.FirstBlock{
    margin-top: 70px;
    display: flex;
    display: -webkit-flex; 
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
}

.FirstWrapper{
    display: flex;
    display: -webkit-flex; 
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 1518px;
}

.HeaderWrapper{
    display: flex;
    display: -webkit-flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1518px;
    height: 220px;
}

.TextHeader{
    font-family: 'Inter-Bold';
    font-size: 120px;
    color: #363638;
    line-height: 110.523%;
}

.TextHeader p{
    margin-top: 0;
}

.PointsWrapper{
    display: flex;
    display: -webkit-flex; 
    justify-content: center;
    flex-direction: column;
    height: 220px;
    width: 306px;
}

.LogoPoint{
    
    width: 306px;
    height: 87px;
    background: #696969;
    border-radius: 70px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.20), 0px 3px 6px 0px rgba(0, 0, 0, 0.20), 0px 11px 11px 0px rgba(0, 0, 0, 0.17), 0px 25px 15px 0px rgba(0, 0, 0, 0.10), 0px 44px 17px 0px rgba(0, 0, 0, 0.03), 0px 68px 19px 0px rgba(0, 0, 0, 0.00);
}

.LogoPoint img{
    border-radius: 70px;
    width: 306px;
    height: 87px;
}

.TextPoint{
    margin-top: 57px;
    width: 306px;
    height: 76px;
}

.TextPoint img{
    width: 306px;
    height: 90px;
}


.BodyWrapper{
    margin-top: 80px;
    border-radius: 60px 60px 0px 0px;
    height: 550px;
    width: 1518px;
}

.BodyWrapper img{
    height: 550px;
    width: 1518px;
    border-radius: 60px 60px 0px 0px;
}

.ArrowStick{
    position: absolute;
    width: 258px;
    height: 258px;
    left: 878px;
    top: 136px;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.20)) drop-shadow(0px 9.542401313781738px 19.084802627563477px rgba(0, 0, 0, 0.20)) drop-shadow(0px 34.35264587402344px 34.35264587402344px rgba(0, 0, 0, 0.17)) drop-shadow(0px 78.24768829345703px 45.80352783203125px rgba(0, 0, 0, 0.10)) drop-shadow(0px 137.41058349609375px 55.34592819213867px rgba(0, 0, 0, 0.03)) drop-shadow(0px 215.6582794189453px 61.07136917114258px rgba(0, 0, 0, 0.00));
}

.ArrowStick img{
    width: 258px;
    height: 258px;
}

.SIBlock{
    margin-top: 50px;
    width: 1518px;
    height: 550px;
    display: flex;
    display: -webkit-flex; 
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    
    border-radius: 0px 0px 60px 60px;
    background: #363638;
    background-image: url('./images/Angels.webp');
    background-size: 44%;
    background-repeat: no-repeat;
}

.TrackImg{
    background-image: url('./images/TrachBig.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 762px;
    height: 442px;
    margin-right: 50px;
    margin-left: 40px;
}

.AboutUsWrapper{
    display: flex;
    display: -webkit-flex; 
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 626px;
    height: 345px;
}

span{
    color: #486ADA;
}

.AboutBody{

    width: 626px;
    height: 216px;
    color: #FFF;
    font-size: 21px;
    text-align: justify;
    line-height: 113.023%;
    font-style: normal;
}


.PointsPlusesWrapper{
    padding: 12px 14px;
    display: flex;
    display: -webkit-flex; 
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 626px;
    height: 99px;
    border-radius: 17px;
    border: 2px solid #486ADA;
    background: #363638;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 15px 0px rgba(0, 0, 0, 0.15), 0px 27px 27px 0px rgba(0, 0, 0, 0.13), 0px 60px 36px 0px rgba(0, 0, 0, 0.08), 0px 106px 43px 0px rgba(0, 0, 0, 0.02), 0px 166px 47px 0px rgba(0, 0, 0, 0.00);
}

.PointPlus{
    display: flex;
    display: -webkit-flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.BigNum{
    color: #486ADA;
    font-family: 'Inter-Bold';
    font-size: 66px;
}

.TextPluses{
    color: #FFF;
    font-size: 18px;
    margin-left: 10px;
    line-height: 107.523%;
}

.SIBBlockOne{
    display: -webkit-flex; 
    display: flex;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.SIBBlockTwo{
    width: 1418px;
    height: 40px;
    display: flex;
    display: -webkit-flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
    margin-left: 50px;
}

.Location{
    display: -webkit-flex;
    display: flex;
 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 625px;
    height: 40px;
    margin-bottom: 20px;
    column-gap: 15px;
    
}

.GeoLogo{
    width: 40px;
    height: 40px;
    background-image: url('./images/GeoLogo.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.GeoText{
    color: #FFF;
    font-size: 21px;
    line-height: 116.8%;
}
@media (max-width: 1000px) and (min-width: 769px){
    .FirstBlock{
        margin-top: 7vw;
    }
    
    .HeaderWrapper{
        width: 96vw;
        height: 16.7vw;
    }
    
    .FirstWrapper{
        width: 96vw;
    }
    .TextHeader{
        font-size: 7vw;
        line-height: 166.023%;
    }
    
    .PointsWrapper{
        justify-content: space-between;
        height: 16.7vw;
        width: 27vw;
    }
    
    .LogoPoint{
        
        width: 27vw;
        height: 7.7vw;
        border-radius: 7vw;
    }
    .LogoPoint img{
        width: 27vw;
        height: 7.7vw;
        border-radius: 7vw;
    }
    
    .TextPoint{
        margin-top: 0;
        width: 27vw;
        height: 7.6vw;
    }

    .TextPoint img{
        margin-top: 0;
        width: 27vw;
        height: 7.6vw;
    }
    
    .BodyWrapper{
        margin-top: 7vw;
        border-radius: 6vw 6vw 0px 0px;
        height: 34.7vw;
        width: 96vw;
    }
    .BodyWrapper img{
        height: 34.7vw;
        width: 96vw;
        border-radius: 6vw 6vw 0px 0px;
    }
    
    .ArrowStick{
        width: 25.8vw;
        height: 25.8vw;
        left: 40vw;
        top: 10.9vw;
    }
    .ArrowStick img{
        width: 25.8vw;
        height: 25.8vw;
    }
    
    .SIBlock{
        margin-top: 5vw;
        width: 96vw;
        height: 58vw;
        
        border-radius: 0px 0px 6vw 6vw;
        background-size: 72.3%;
    }
    
    .TrackImg{
        margin: 0;
        background-image: url('./images/Track.png');
        background-size: 100%;
        width: 29vw;
        height: 40.8vw;
    }
    
    .AboutUsWrapper{
        width: 65.6vw;
        height: 40.8vw;
    }
    
    .AboutBody{
        width: 62.6vw;
        height: 21.6vw;
        font-size: 2.1vw;
        text-align: justify;
    }
    

    
    .PointsPlusesWrapper{
        justify-content: space-between;
        padding:  1.4vw 1.2vw;
        width: 62.6vw;
        height: 9.9vw;
        border-radius: 17px;
        border: 2px solid #486ADA;
    }
    
    .BigNum{
        font-size: 6vw;
    }
    
    .TextPluses{
        font-size: 1.8vw;
        width: 15.2vw;
        margin-left: 0.7vw;
    }
    
    
    .SIBBlockTwo{
        flex-direction: column;
        width: 62.6vw;
        height: 10vw;
        margin-bottom: 3vw;
        margin-left: 30.4vw;
    }
    
    .Location{
        width: 62.5vw;
        height: 4vw;
        margin-bottom: 2vw;
    }
    
    .GeoLogo{
        width: 4vw;
        height: 4vw;
        background-size: 100%;
    }
    
    .GeoText{
        font-size: 2.1vw;
        margin-left: 1.5vw;
    }

}

@media (max-width: 1920px) and (min-width: 1001px) {
    .FirstBlock{
        margin-top: 3.6vw;
    }

    .HeaderWrapper{
        width: 79vw;
        height: 11.5vw;
    }

    .FirstWrapper{
        width: 79.1vw;
    }

    .TextHeader{
        font-size: 6.3vw;
    }

    .PointsWrapper{
        justify-content: space-between;
        height: 11.5vw;
        width: 15.9vw;
    }

    .LogoPoint{
    
        width: 15.9vw;
        height: 4.5vw;
        border-radius: 3.6vw;
    }
    .LogoPoint img{
        width: 15.9vw;
        height: 4.5vw;
        border-radius: 3.6vw;
    }

    .TextPoint{
        margin: 0;
        width: 16vw;
        height: 4.5vw;
    }

    .TextPoint img{
        margin: 0;
        width: 16vw;
        height: 4.5vw;
    }

    .BodyWrapper{
        margin-top: 4.2vw;
        border-radius: 3.1vw 3.1vw 0px 0px;
        height: 28.6vw;
        width: 79vw;
    }
    .BodyWrapper img{
        height: 28.6vw;
        width: 79vw;
        border-radius: 3.1vw 3.1vw 0px 0px;
    }
    

    .ArrowStick{
        width: 13.4vw;
        height: 13.4vw;
        left: 45.7vw;
        top: 7vw;
    }
    .ArrowStick img{
        width: 13.4vw;
        height: 13.4vw;
    }

    .SIBlock{
        margin-top: 2.6vw;
        height: 30.6vw;
        width: 79vw;
        border-radius: 0px 0px 3.1vw 3.1vw;
        background-image: url('./images/AngelsBig.webp');
        background-size: 48%;
    }

    .TrackImg{
        background-image: url('./images/TrachBig.png');
        width: 39.7vw;
        height: 23vw;
        margin-left: 2.1vw;
        margin-right: 2.6vw;
    }
    
    .AboutUsWrapper{
        width: 32.6vw;
        height: 18vw;
    }

    .AboutBody{
        width: 32.6vw;
        height: 11.3vw;
        font-size: 1.1vw;
        text-align: justify;
    }


    .PointsPlusesWrapper{
        padding: 0.7vw 0.6vw;
        width: 32.6vw;
        height: 5.2vw;
        border-radius: 0.9vw;
        border: 0.1vw solid #486ADA;
        margin-top: 1.6vw;
    }
    
    .BigNum{
        font-size: 3.4vw;
    }

    .TextPluses{
        font-size: 0.9vw;
        margin-left: 0.5vw;
    }

    .SIBBlockTwo{
        width: 73.9vw;
        height: 2.1vw;
        flex-direction: row;
        margin-bottom: 2.6vw;
        margin-left: 2.6vw;
    }
    
    .Location{
        width: 32.6vw;
        height: 2.1vw;
        margin-bottom: 0;
    }

    .GeoLogo{
        width: 2.1vw;
        height: 2.1vw;
    }

    .GeoText{
        font-size: 1.1vw;
        margin-left: 0.8vw;
    }

    
}

@media (max-width: 768px) and (min-width: 481px){
    .FirstBlock{
        margin-top: 6.5vw;
    }
    
    .HeaderWrapper{
        width: 95vw;
        height: 18.6vw;
    }
    
    .FirstWrapper{
        width: 96vw;
    }
    .TextHeader{
        position: relative;
        font-size: 10.2vw;
        line-height: 110.523%;
    }
    
    .PointsWrapper{
        position: absolute;
        justify-content: space-between;
        width: 35.2vw;
        height: 10vw;
        left: 59.6vw;
        top: 9.9vw;
    }

    .PointsWrapper.tech{
        left: 59.6vw;
        top: 9.9vw;
    }
    .PointsWrapper.vac{
        left: 61.6vw;
        top: 9.9vw;
    }
    
    .LogoPoint{
        display: none;
        width: 35.2vw;
        height: 10vw;
        border-radius: 7vw;
    }
    .LogoPoint img{
        display: none;
    }

    
    .TextPoint{
        display: block;
        margin-top: 0;
        width: 35.2vw;
        height: 9.9vw;
    }

    .TextPoint img{
        display: block;
        margin-top: 0;
        width: 35.2vw;
        height: 9.9vw;
    }
    
    .BodyWrapper{
        margin-top: 8vw;
        border-radius: 8vw 8vw 0px 0px;
        height: 45.2vw;
        width: 95vw;
    }

    .BodyWrapper img{
        height: 45.2vw;
        width: 95vw;
        border-radius: 8vw 8vw 0px 0px;
    }
    
    .ArrowStick{
        width: 19.8vw;
        height: 19.8vw;
        left: 48.7vw;
        top: 17.4vw;
    }
    .ArrowStick img{
        width: 19.8vw;
        height: 19.8vw;
    }
    
    .SIBlock{
        margin-top: 3.9vw;
        width: 95vw;
        height: 73.6vw;
        align-items: center;
        border-radius: 0px 0px 8vw 8vw;
        background-size: 100%;
    }
    .SIBBlockOne{
        margin-top: 3.9vw;  
    }
    .TrackImg{
        display: none;
    }
    
    .AboutUsWrapper{
        width: 81.5vw;
        height: 44.9vw;
    }
    
    .AboutBody{
        width: 81.5vw;
        height: 28vw;
        font-size: 2.7vw;
        text-align: justify;
    }
    
    
    .PointsPlusesWrapper{
        padding: 1.6vw 1.8vw;
        width: 81.5vw;
        height: 12.9vw;
        border-radius: 2.2vw;
        border: 2px solid #486ADA;
    }
    
    .BigNum{
        font-size: 8.6vw;

    }
    
    .TextPluses{
        font-size: 2.2vw;
        margin-left: 0.7vw;
    }
    
    
    .SIBBlockTwo{
        flex-direction: column;
        width: 81.5vw;
        height: 13vw;
        margin-bottom: 3.9vw;
        margin-left: 0;
    }
    
    .Location{
        width: 81.5vw;
        height: 5.2vw;
        margin-bottom: 2.6vw;
    }
    
    .GeoLogo{
        width: 5.2vw;
        height: 5.2vw;
        background-size: 100%;
    }
    
    .GeoText{
        font-size: 2.7vw;
        margin-left: 2vw;
    }
}

@media (max-width: 480px) and (min-width: 321px){
    .FirstBlock{
        margin-top: 6.3vw;
    }
    
    .HeaderWrapper{
        width: 92vw;
        height: 17.9vw;
    }
    
    .FirstWrapper{
        position: relative;
        width: 92vw;
    }
    .TextHeader{
        position: relative;
        font-size: 9vw;
        line-height: 110.523%;
    }
    
    .PointsWrapper{
        position: absolute;
        justify-content: space-between;
        width: 33.5vw;
        height: 9.6vw;
        left: 53vw;
        top: 9vw;
    }

    .PointsWrapper.tech{
        
        left: 53vw;
        top: 9vw;
    }
    .PointsWrapper.vac{
        left: 58vw;
        top: 9vw;
    }
    
    .LogoPoint{
        display: none;
    }
    
    .TextPoint{
        width: 33.5vw;
        height: 9.6vw;
        margin-top: 0;
    }
    .TextPoint img{
        width: 33.5vw;
        height: 9.6vw;
        margin-top: 0;
    }

    
    
    .BodyWrapper{
        margin-top: 6.3vw;
        border-radius: 6.3vw 6.3vw 0px 0px;
        height: 43.3vw;
        width: 92vw;
    }
    .BodyWrapper img{
        height: 43.3vw;
        width: 92vw;
        border-radius: 6.3vw 6.3vw 0px 0px;
    }
    
    .ArrowStick{
        width: 21.2vw;
        height: 21.2vw;
        left: 47.5vw;
        top: 19.2vw;
    }
    
    .ArrowStick img{
        width: 21.2vw;
        height: 21.2vw;
    }

    .SIBlock{
        margin-top: 4.2vw;
        width: 92vw;
        height: 148.5vw;
        align-items: center;
        border-radius: 0px 0px 6.3vw 6.3vw;
        background-image: none;
    }
    
    .TrackImg{
        display: none;
    }
    
    .AboutUsWrapper{
        width: 83.3vw;
        height: 107.3vw;
    }
    
    .AboutBody{
        text-align: justify;
        width: 83.3vw;
        height: 70vw;
        font-size: 4.4vw;
    }
    
    .PointsPlusesWrapper{
        flex-direction: column;
        margin-top: 4.2vw;
        padding: 2.9vw 2.5vw;
        width: 83.3vw;
        height: 36.9vw;
        border-radius: 3.5vw;
        border: 2px solid #486ADA;
    }
    
    .BigNum{
        font-size: 13.6vw;
    }
    
    .TextPluses{
        font-size: 4.3vw;
        margin-left: 2.1vw;
    }
    
    
    .SIBBlockTwo{
        flex-direction: column;
        width: 83.3vw;
        height: 25vw;
        margin-bottom: 4.2vw;
        margin-left: 0;
    }
    
    .Location{
        align-items: flex-start;
        width: 83.3vw;
        height: 10.4vw;
        margin-bottom: 4.2vw;
    }
    
    .GeoLogo{
        width: 9.3vw;
        height: 9.3vw;
        background-size: 100%;
    }
    
    .GeoText{
        font-size: 4vw;
    }

    #Small{
        width: 10.5vw;
    }
}

@media (max-width: 320px){
    .FirstBlock{
        margin-top: 9.4vw;
    }
    
    .HeaderWrapper{
        width: 92vw;
        height: 17.9vw;
    }
    
    .FirstWrapper{
        position: relative;
        width: 92vw;
    }
    .TextHeader{
        position: relative;
        font-size: 9vw;
        line-height: 110.523%;
    }
    
    .PointsWrapper{
        position: absolute;
        justify-content: space-between;
        width: 33.5vw;
        height: 9.6vw;
        left: 52vw;
        top: 9vw;
    }

    .PointsWrapper.tech{
        left: 52vw;
        top: 9vw;
    }
    
    .PointsWrapper.vac{
        left: 58vw;
        top: 9vw;
    }
    .LogoPoint{
        display: none;
        width: 33.5vw;
        height: 9.6vw;
        border-radius: 14.6vw;
    }
    
    .TextPoint{
        width: 36.9vw;
        height: 10.6vw;
        margin-top: 0;
    }

    .TextPoint img{
        width: 36.9vw;
        height: 10.6vw;
        margin-top: 0;
    }

    
    .BodyWrapper{
        margin-top: 6.3vw;
        border-radius: 6.3vw 6.3vw 0px 0px;
        height: 43.3vw;
        width: 92vw;
    }

    .BodyWrapper img{
        height: 43.3vw;
        width: 92vw;
        border-radius: 6.3vw 6.3vw 0px 0px;
    }
    
    .ArrowStick{
        width: 21.2vw;
        height: 21.2vw;
        left: 47.5vw;
        top: 19.2vw;
    }

    .ArrowStick img{
        width: 21.2vw;
        height: 21.2vw;
    }
    
    .SIBlock{
        margin-top: 4.2vw;
        width: 92vw;
        height: 332.5vw;
        align-items: center;
        border-radius: 0px 0px 6.3vw 6.3vw;
        background-image: none;
    }
    
    .TrackImg{
        display: none;
    }
    
    .AboutUsWrapper{
        width: 83.3vw;
        height: 246.5vw;
    }
    
    .AboutBody{
        text-align: left;
        width: 83.3vw;
        height: 172.5vw;
        font-size: 6.6vw;
    }
    
    
    .PointPlus{
        flex-direction: column;
    }
    .PointsPlusesWrapper{
        flex-direction: column;
        margin-top: 6.3vw;
        gap: 10px;
        padding: 3.1vw 3.1vw;
        width: 83.3vw;
        height: 88.5vw;
        border-radius: 3.5vw;
        border: 2px solid #486ADA;
    }
    
    .BigNum{
        font-size: 20.6vw;
    }
    
    .TextPluses{
        text-align: center;
        font-size: 6.6vw;
        margin-left: 0;
    }
    
    
    .SIBBlockTwo{
        flex-direction: column;
        width: 83.3vw;
        height: 60.9vw;
        margin-bottom: 4.7vw;
        margin-left: 0;
    }
    
    .Location{
        align-items: flex-start;
        width: 80vw;
        height: 23.4vw;
        gap: 5vw;
    }
    
    .GeoLogo{
        width: 22.5vw;
        height: 12.5vw;
        background-size: 100%;
    }
    
    .GeoText{
        font-size: 6vw;
    }

    
    #Small{
        width: 25.5vw;
    }

    p{
        text-align: left;
    }
}