.Points:hover{
    color: #486ADA;
    transition: 0.25s;
    cursor: pointer;
}

.Points{
 
    color: #363638;

}






.NavBarConteiner{
    display: none;
    position: absolute;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 92vw;
    height: 80px;
    border-bottom:2px solid #486ADA ;
    margin-top: 0vw;
    padding-bottom: 2.2vw;
    z-index: 101;
}

.menu-burger__header {
	position: relative;
    width: 9.4vw;
	height: 9.4vw;
    display: block;
	float: right;
	z-index: 30;
   }
.menu-burger__header span, .menu-burger__header:after, .menu-burger__header:before{
	height: 0.6vw;
	width: 4.4vw;
	left: 2.5vw;
	position: absolute;
	background: #486ADA;
	border-radius: 4.7vw;
	margin: 0 auto;
}
.menu-burger__header span{
	top: 4.4vw;
	left: 2.5vw;
}
.menu-burger__header:after, .menu-burger__header:before{
	content: '';
}
.menu-burger__header:after{
	bottom: 2.8vw;
	left: 2.5vw;
	transition: 0.4s;
}
.menu-burger__header:before{
	top: 2.8vw;
	left: 2.5vw;
	transition: 0.4s;
}
.menu-burger__header.open-menu span {
	opacity:0; 
  
}
.menu-burger__header.open-menu:before {
	transform: rotate(45deg);
	left: 2.5vw;
    top: 4.4vw;
    transition: 0.4s;
}
.menu-burger__header.open-menu:after {
	transform: rotate(-45deg);
	height: 0.6vw;
	width: 4.4vw;
	left: 2.5vw;
    top: 4.4vw;
    transition: 0.4s;
}




@media (max-width: 480px) and (min-width: 321px){
    .NavBar{
        flex-direction: column;  
        width: 92vw;
    }


    .NavBarConteiner{
        position: relative;
        display: flex;
    }
    .NavBarPage{
        flex-direction: column;  
        width: 100vw;
        height: auto;
        margin-top: 4.2vw;
    }

    .Points{
        color: #FFF;
    }

    .NavWrapper{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
       border-bottom: none;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }

    .NavWrapperPage{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
       border-bottom: none;
       position: absolute;
       top: 0;
       left: 0;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }

    .Logo{
        width: 47.7vw;
        height: 8vw;
    }

    .NavPanel{
        position: absolute;
        top: -266.3vw;
        transition: 1s;
        display: none;
        opacity: 0;
    }

    .NavWrapper.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        position: absolute;
        display: flex;
        transition: 1s;
        width: 100vw;
        height: 200vh;
    }
    .NavWrapperPage.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        position: absolute;
        display: flex;
        transition: 1s;
        top: 0vw;
        left: 0vw;
        width: 120vw;
        height: 200vh;
    }

    .NavBar.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
    }
    .NavBarPage.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
        margin-top: 4.2vw;
    }

    .NavPanel.open-menu{
        opacity: 100;
        position: absolute;
        top: 34.4vw;
        left: 5vw;
        transition: 1s;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        color: #FFF;
        width: 87.5vw;
        height: 78.1vw;
        font-size: 6.6vw;
        
    }
}

@media (max-width: 320px){
    .NavBar{
        flex-direction: column;  
        width: 92vw;
    }
    .NavBarConteiner{
        display: flex;
        position: relative;
    }
    .NavBarPage{
        flex-direction: column;  
        width: 106vw;
        height: 60.6vw;
    }

    .Points{
        color: #FFF;
    }

    .NavWrapper{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
        position: absolute;
        top: 0;
        left: 0;
       border-bottom: none;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }

    .NavWrapperPage{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
       border-bottom: none;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }


    .Logo{
        margin-top: 5px;
        width: 74vw;
        height: 14vw;
    }

    .NavPanel{
        position: absolute;
        top: -266.3vw;
        transition: 1s;
        display: none;
        opacity: 0;
    }

    .NavWrapper.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        display: flex;
        transition: 1s;
        width: 100vw;
        height: 200vh;
    }
    .NavWrapperPage.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        position: relative;
        display: flex;
        transition: 1s;
        top: -44vw;
        left: -36vw;
        width: 120vw;
        height: 200vh;
    }

    .NavBar.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
    }
    .NavBarPage.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
    }

    .NavPanel.open-menu{
        opacity: 100;
        position: absolute;
        top: 34.4vw;
        left: 8vw;
        transition: 1s;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        color: #FFF;
        width: 87.5vw;
        height: 78.1vw;
        font-size: 6.6vw;
        z-index: 1000;
    }
}