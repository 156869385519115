.VacansWrapp{
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.Vacans{
    position: relative;
}

.MoreInfo{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    left: 16px;
    top: 16px;
    width: 1207px;
    padding-right: 20px;
}

.RoadMore{
    display: none;
    opacity: 0;
    transition: 1.5s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 175px;
}

.ArrowMore{
    margin-left: 20px;
    width: 39px;
    height: 24px;

}

.RoadMore:hover .ArrowMore{
    margin-left: 40px;
}

.BMore{
    display: flex;
    opacity: 0;
    transition: 1s;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px 10px;
    width: 359px;
    height: 53px;
    color: #FFF;
    gap: 15px;
    border-radius: 12px;
    background: #7897FF;
    font-family: "Inter";
    
}

.Vacans{
    border-radius: 20px 0px 0px 20px;
    overflow: hidden;
}

.VacansName.Gradi{
    transition: 0.5s;
    left: -1000px;
    position: absolute;
    width: 1000px;
    border-radius: 20px 0px 0px 20px;
    background: linear-gradient(90deg, #486ADA 26.87%, rgba(54, 54, 56, 0.00) 100%);
    
    backdrop-filter: blur(2px);
}

.Vacans:hover .VacansName.Gradi{
    left: 0;
}

.Vacans:hover .BMore{
    opacity: 1;
}

.Vacans:hover .RoadMore{
    opacity: 0;
}

@media(max-width: 1920px){
    
.MoreInfo{
    left: 0.8vw;
    top: 0.8vw;
    width: 55.9vw;
    padding-right: 1vw;
}

.RoadMore{
    display: none;
    width: 9.1vw;
    font-size: 1.1vw;
}

.ArrowMore{
    margin-left: 1vw;
}

.RoadMore:hover .ArrowMore{
    margin-left: 2.1vw;
}

.BMore{

    padding: 0.5vw 0.5vw;
    width: 18.7vw;
    height: 2.8vw;
    gap: 0.8vw;
    border-radius: 0.6vw;
    font-size: 1vw;
    
}

.VacansName.Gradi{
    left: -100vw;
    width: 52.1vw;
    border-radius: 20px 0px 0px 20px;
}
}
@media(max-width: 1000px){
    .MoreInfo{
        left: 1.6vw;
        top: 1.6vw;
        width: 60vw;
        padding-right: 1vw;
    }
    
    .RoadMore{
        display: none;
        width: 9.1vw;
        font-size: 2.1vw;
    }
    
    .ArrowMore{
        margin-left: 1vw;
    }
    
    .RoadMore:hover .ArrowMore{
        margin-left: 2.1vw;
    }
    
    .BMore{
        font-size: 2.1vw;
        padding: 0.5vw 0.5vw;
        width: 35.9vw;
        height: 5.3vw;
        gap: 0.8vw;
        border-radius: 1.2vw;
        
    }
    
    .VacansName.Gradi{
        left: -100vw;
        width: 71.1vw;
        border-radius: 20px 0px 0px 20px;
    }

}
@media(max-width: 768px){
    .MoreInfo{
        display: flex;
        position: unset;
        left: 2.1vw;
        top: 3.8vw;
        width: 54.3vw;
        padding-right: 1vw;
    }
    
    .RoadMore{
        display: none;
        width: 22.8vw;
        font-size: 2.7vw;
    }
    
    .ArrowMore{
        margin-left: 1vw;
    }
    
    .RoadMore:hover .ArrowMore{
        margin-left: 2.1vw;
    }
    
    .BMore{
        display: flex;
        opacity: 1;
        position: unset;
        font-size: 2.7vw;
        padding: 1.3vw;
        width: 28.8vw;
        height: 6.9vw;
        gap: 0.8vw;
        border-radius: 1.2vw;
        
    }
    
    .VacansName.Gradi{
        display: none;    
    }

}

@media(max-width: 480px){

    .MoreInfo{
        position: unset;
        width: 83.3vw;
        align-items: center;
    }
    .BMore{
        justify-content: center;
        align-items: center;
        font-size: 4.4vw;
        position: unset;
        opacity: 1;
        padding: 10px 10px;
        width: 83.3vw;
        height: 11vw;
        gap: 15px;
        border-radius: 12px;    
    }

    .VacansName.Gradi{
        display: none;    
    }

    .RoadMore{
        display: none;
        font-size: 4.4vw;
        opacity: 1;
        width: 34.4vw;
    }
}

@media(max-width: 320px){
    .MoreInfo{

        flex-direction: column;
        position: unset;
        width: 75vw;
    }
    .BMore{
        font-size: 6.6vw;
        position: unset;
        opacity: 1;
        padding: 10px 10px;
        width: 75vw;
        height: 53px;
        gap: 15px;
        border-radius: 12px;    
    }
    .RoadMore{
        display: none;
        font-size: 6.6vw;
        justify-content: space-between;
        padding: 0 11.9vw;

        opacity: 1;
        transition: 1.5s;
        width: 75vw;
    }

    .RoadMore img{
        padding-top: 0.5vw;
        width: 12.2vw;
        height: 7.5vw;
    }
}
