.Article{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 77.1vw;
    height: 122vw;
    font-family: "Inter";
    padding: 20px;
}


.TechImages{
    background-image: var(--Imgur);
    background-size: cover;
    background-position: center;
    width: 68.8vw;
    height: 52.7vw;
    border-radius: 3.1vw;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.20), 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 15px 15px 0px rgba(0, 0, 0, 0.17), 0px 35px 21px 0px rgba(0, 0, 0, 0.10), 0px 61px 25px 0px rgba(0, 0, 0, 0.03), 0px 96px 27px 0px rgba(0, 0, 0, 0.00);
}

.DescriptWrapp{
    color: #fff;
    font-style: normal;
    line-height: 118.023%; 
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 68.8vw;
    height: 54.3vw;
}

.DescriptHeader{
    border-bottom: 0.7vw solid #fff;
    width: 75px;

    padding-bottom: 2.1vw;
    font-size: 5.8vw;
    line-height: normal;

}

.DescriptBody{
    padding-left: 5px;
    margin-top: 4.8vw;
    width: 68.8vw;
    margin-left: 15px;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}

li{
    color: #FFF;
    font-family: 'Inter';
    font-size: 3.3vw;
    list-style: outside;
    font-style: normal;
    line-height: normal;
}
 ol{
    color: #FFF;
    font-size: 3.3vw;
    max-width: 200px;
    margin-left: -22px;
    margin-top: 10px;

}

@media (max-width: 320px){
    .Article{
        padding: 15px;
        width: 81.3vw;
        height: 154.2vw;
    }


    
    
    .TechImages{
        width: 71vw;
        height: 75vw;
        border-radius: 4.7vw;
    }
    
    .DescriptWrapp{
        width: 71.9vw;
        height: 64vw;
    }
    
    .DescriptHeader{
        border-bottom: 0.9vw solid #fff;
        width: 23.4vw;
        padding-bottom: 3.1vw;
        font-size: 6vw;
    
    }
    
    .DescriptBody{
        margin-top: 6.3vw;
        width: 60.9vw;
        font-size: 4.4vw;
    }
    ul{
        list-style: outside;
    }

    ol{
        color: #FFF;
        font-size: 4.2vw;
        max-width: 200px;
        margin-left: -22px;
        margin-top: 10px;
    
    }
    
    li{
        font-size: 4.2vw;
        list-style: outside;
    }

    
}