@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('./fonts/inter/Inter-Black.ttf') format('truetype');
  font-weight: 400;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root{
  display: flex;
  justify-content: center;
}

.App{
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
}

body{
  font-family: "Inter", sans-serif;
  -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

html {
  scroll-behavior: smooth;
}

