.SecondBlock{
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Inter";
}

.SecondWrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.HeaderSecond{
    font-family: "Inter-Bold";
    color: #363638;
    font-size: 50px;
    line-height: 110.523%;
}

.BodyWrapperSec{
    margin-top: 50px;
    width: 1518px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px 30px;
}

.PerspectWrap{
    width: 486px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.PerspHeader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.PerspectIcon{
    width: 129px;
    height: 129px;
    border-radius: 47px;
    background-color: #486ADA;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 2px 5px 0px rgba(0, 0, 0, 0.15), 0px 9px 9px 0px rgba(0, 0, 0, 0.13), 0px 20px 12px 0px rgba(0, 0, 0, 0.08), 0px 36px 14px 0px rgba(0, 0, 0, 0.02), 0px 56px 16px 0px rgba(0, 0, 0, 0.00);

}

.PerspHText{
    width: 327px;
    margin-left: 30px;
    color: #363638;
    font-size: 27px;
    line-height: 113.023%;
    font-family: 'Inter-Bold';
   

}

.PerspBody{
    font-family: "Inter";
    margin-top: 30px;
    color: #363638;
    text-align: left;
    font-size: 21px;
    line-height: 113.023%;
}

@media (max-width: 1920px){

    .SecondBlock{
        margin-top: 7.8vw;
    }

    .SecondWrapper{
        padding: 0;
    }

    .HeaderSecond{
        font-size: 2.6vw;
    }


    .BodyWrapperSec{
    margin-top: 2.6vw;
    width: 79.1vw;
    gap: 2.6vw 1.6vw;
    }

    .PerspectWrap{
        width: 25.3vw;
    }

    .PerspectIcon{
        width: 6.7vw;
        height: 6.7vw;
        border-radius: 2.4vw;
    }

    .PerspHText{
        width: 17vw;
        margin-left: 1.6vw;
        font-size: 1.4vw;    
    }
    
    .PerspBody{
        margin-top: 1.6vw;
        font-size: 1.1vw;
    }
    

}

@media (max-width: 1000px){
    .SecondBlock{
        margin-top: 10vw;
    }
    
    .SecondWrapper{
        padding: 2vw;
    }
    
    .HeaderSecond{
        font-size: 5vw;
    }
    
    .BodyWrapperSec{
        margin-top: 5vw;
        width: 96vw;
        gap: 3vw 2vw;
    }
    
    .PerspectWrap{
        width: 47vw;
    }
    
    .PerspectIcon{
        width: 13vw;
        height: 13vw;
        border-radius: 3.7vw;
    }
    
    .PerspHText{
        width: 31.1vw;
        margin-left: 3vw;
        font-size: 2.7vw;    
    }
    
    .PerspBody{
        margin-top: 3vw;
        font-size: 2.1vw;
    }
}

@media (max-width: 768px){
    .SecondBlock{
        margin-top: 10.4vw;
    }
    
    .HeaderSecond{
        font-size: 6.5vw;
    }

    .SecondWrapper{
        align-items: center;
    }
    
    .BodyWrapperSec{
        margin-top: 6.5vw;
        width: 95vw;
        gap: 3.9vw 2.6vw;
    }
    
    .PerspectWrap{
        width: 46.1vw;
    }

    .PerspHeader{
        flex-direction: column;
        justify-content: space-between;
        width: 46.1vw;
    }
    
    .PerspectIcon{
        width: 16.8vw;
        height: 16.8vw;
        border-radius: 6.1vw;
    }
    
    .PerspHText{
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        height: 8.1vw;
        width: 46.1vw;
        margin-left: 0;
        margin-top: 3.9vw;
        font-size: 3.5vw;  

    }
    
    .PerspBody{
        margin-top: 3.9vw;
        font-size: 2.7vw;
    }
}

@media (max-width: 480px){
    .SecondBlock{
        margin-top: 16.7vw;
    }
    
    .HeaderSecond{
        font-size: 10.2vw;
        text-align: center;
    }

    .SecondWrapper{
        align-items: center;
    }
    
    .BodyWrapperSec{
        margin-top: 10.4vw;
        width: 92vw;
        gap: 3.9vw 6.3vw;
    }
    
    .PerspectWrap{
        width: 92vw;
    }

    .PerspHeader{
        flex-direction: column;
        justify-content: space-between;
        width: 92vw;
        gap: 20px;
    }
    
    .PerspectIcon{
        width: 26.9vw;
        height: 26.9vw;
        border-radius: 9.8vw;
    }
    
    .PerspHText{
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        width: 92vw;
        height: auto;
        margin-left: 0;
        margin-top: 0vw;
        font-size: 5.6vw;  

    }
    
    .PerspBody{
        margin-top: 4.2vw;
        font-size: 4.4vw;
    }
}

@media (max-width: 320px){
    .SecondBlock{
        margin-top: 25vw;
    }
    
    .HeaderSecond{
        font-size: 9.4vw;
        text-align: center;
    }

    .SecondWrapper{
        align-items: center;
    }
    
    .BodyWrapperSec{
        margin-top: 15.6vw;
        width: 92vw;
        gap:6.3vw;
    }
    
    .PerspectWrap{
        width: 92vw;
    }

    .PerspHeader{
        flex-direction: column;
        justify-content: space-between;
        width: 92vw;
    }
    
    .PerspectIcon{
        width: 40.3vw;
        height: 40.3vw;
        border-radius: 14.7vw;
    }
    
    .PerspHText{
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        width: 92vw;
        margin-left: 0;
        margin-top: 0vw;
        font-size: 7.5vw;  

    }
    
    .PerspBody{
        margin-top: 6.3vw;
        font-size: 6.6vw;
    }
}

