.FourthBlock{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 150px;
    width: 1518px;
    font-family: 'Inter-Bold';
}

.FourthBlock p{
    font-family: "Inter";
}

.FourthWrapper{
    width: 1518px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.FourthHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: #363638;
    font-size: 50px;
    line-height: 110.523%;
    width: 1518px;
}

.VacansWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1518px;
    height: 585px;
    flex-direction: column;
    margin-top: 50px;
}

.Vacans{
    width: 1518px;
    height: 85px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.VacansName{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Inter";
    width: 1273px;
    height: 85px;
    color: #FFF;
    font-size: 21px;
    line-height: 118.023%;
    padding: 30px 50px;
    border-radius: 20px 0px 0px 20px;
    background: #363638;
}

.VacansPrice{
    width: 245px;
    height: 85px;
    color: #FFF;
    display: flex;
    border-radius: 0px 20px 20px 0px;
    justify-content: center;
    align-items: center;
    font-family: "Inter-Bold";
    background: #486ADA;
    font-size: 25px;
    line-height: 118.023%; 
}

#Vac{
    margin-top: 30px;
}

@media (max-width: 1920px){

    .FourthBlock{
        margin-top: 7.8vw;
        padding-left: 0;
        width: 79.1vw;
    }

    .FourthWrapper{
        width: 79.1vw;
    }

    .FourthHeader{
        width: 79.1vw;
        font-size: 2.6vw;
    }

    .VacansWrapper{
        justify-content: space-between;
        width: 79.1vw;
        height: 30.5vw;
        margin-top: 2.6vw;
    }

    .Vacans{
        width: 79.1vw;
        height: 4.4vw;
        margin-top: 0;
    }

    .VacansName{
        width: 66.3vw;
        height: 4.4vw;
        font-size: 1.1vw;
        padding: 1.6vw 2.6vw;
        border-radius: 1vw 0px 0px 1vw;

    }

    .VacansPrice{
        height: 4.4vw;
        border-radius: 0px 1vw 1vw 0px;
        font-size: 1.3vw;
    }
    
}

@media (max-width: 1000px){
    .FourthBlock{
        margin-top: 10vw;
        padding-left: 0vw;
        width: 96vw;
    }
    
    .FourthWrapper{
        width: 96vw;
    }
    
    .FourthHeader{
        width: 95vw;
        font-size: 4.5vw;
    }
    
    .VacansWrapper{
        width: 96vw;
        height: 62.5vw;
    }
    
    .Vacans{
        width: 96vw;
        height: 8.5vw;
        margin-top: 1.5vw;
    }
    
    .VacansName{
        width: 73.5vw;
        height: 8.5vw;
        font-size: 2.1vw;
        padding: 3vw 5vw;
        border-radius: 2vw 0px 0px 2vw;
    }
    
    .VacansPrice{
        width: 23vw;
        height: 8.5vw;
        border-radius: 0px 2vw 2vw 0px;
        font-size: 2.5vw;
    }
}

@media (max-width: 768px){
    .FourthBlock{
        margin-top: 10.4vw;
        width: 95vw;
        padding: 0;
    }
    
    .FourthWrapper{
        align-items: center;
        width: 95vw;
        padding: 0;
    }
    
    .FourthHeader{
        justify-content: center;
        width: 89vw;
        font-size: 6.5vw;
    }
    
    .VacansWrapper{
        width: 95vw;
        height: 142.2vw;
        margin-top: 6.5vw;
    }
    
    .Vacans{
        width: 95vw;
        height: 22.3vw;
        margin-top: 1.5vw;

    }
    
    .VacansName{
        gap: 10px;
        width: 65.1vw;
        height: 22.3vw;
        font-size: 2.7vw;
        padding: 3.9vw 6.5vw;
        border-radius: 2vw 0px 0px 2vw;
    }
    
    .VacansPrice{
        width: 60vw;
        height: 22.3vw;
        border-radius: 0px 2vw 2vw 0px;
        font-size: 3.3vw;
    }
}

@media (max-width: 480px){
    .FourthBlock{
        padding: 0;
        margin-top: 16.7vw;
        width: 92vw;
    }
    
    .FourthWrapper{
        align-items: center;
        width: 92vw;
        padding: 0;
    }
    
    .FourthHeader{
        width: 83.3vw;
        text-align: center;
        font-size: 10.4vw;
    }
    
    .VacansWrapper{
        width: 92vw;
        height: auto;
        gap: 15px;
        padding: 0;
        justify-content: space-between;
    }
    
    .Vacans{
        flex-direction: column;
        width: 92vw;
        height: auto;
        margin-top: 1.5vw;
    }
    
    .VacansName{
        justify-content: space-between;
        text-align: center;
        align-items: center;
        width: 92vw;
        height: auto;
        gap: 4.2vw;
        font-size: 4.4vw;
        padding: 3.9vw 6.5vw;
        border-radius: 4.2vw 4.2vw 0px 0;
    }
    
    .VacansPrice{
        width: 92vw;
        height: auto;
        padding: 5.8vw 4.2vw;
        border-radius: 0px 0 4.2vw 4.2vw;
        font-size: 5.2vw;
    }
}

@media (max-width: 320px){
    .FourthBlock{
        padding: 0;
        margin-top: 25vw;
        width: 87.5vw;
    }
    
    .FourthWrapper{
        align-items: center;
        width: 87.5vw;
        padding: 0;
    }
    
    .FourthHeader{
        width: 83.3vw;
        text-align: center;
        font-size: 9.4vw;
    }
    
    .VacansWrapper{
        width: 87.5vw;
        height: auto;
        margin-top: 15.6vw;
        justify-content: space-between;
        gap: 15px;
    }
    
    .Vacans{
        flex-direction: column;
        justify-content: center;
        width: 87.5vw;

        margin-top: 0;
        height: auto;
    }
    
    .VacansName{
        justify-content: space-between;
        text-align: center;
        width: 87.5vw;
        height: auto;

        font-size: 6.6vw;
        padding: 6.3vw 9.4vw;
        border-radius: 6.3vw 6.3vw 0px 0;
    }
    
    .VacansPrice{
        width: 87.5vw;
        height: auto;
        border-radius: 0px 0 6.3vw 6.3vw;
        font-size: 7.8vw;
        padding:6.3vw 8.8vw;
    }
}