.Points:hover{
    color: #486ADA;
    transition: 0.25s;
    cursor: pointer;
}

.Points{
 
    color: #363638;

}

.NavBarConteiner{
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 92vw;
    height: 25vw;
    border-bottom:2px solid #486ADA ;
    margin-top: 0vw;
    padding-bottom: 2.2vw;
    z-index: 101;
}

.NavBar{
    font-family: "Inter";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    width: 1518px;
}

.NavWrapper{
   width: 1518px;
   display: flex;
   height: 80px;
   justify-content: space-between;
   align-items: center;
   border-bottom: 2px solid #486ADA;
}


.Logo{
    width: 229px;
    height: 40px;
    background-image: url('./images/Logo.svg');
    background-size: 100%;
    background-repeat: no-repeat;
}

.NavPanel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    height: 19px;
}




@media (max-width: 1000px) and (min-width: 900px){

    .NavBar{   
        width: 100vw;
        height: 8vw;
    }

    .NavWrapper{
       width: 96vw;
       height: 8vw;
       border-bottom: 2px solid #486ADA;
    }


    .Logo{
        width: 22.9vw;
        height: 4vw;
    }

    .NavPanel{
        height: 1.9vw;
    }

    .NavBarConteiner{
        display: none;
    }
    
   
}

@media (max-width: 1920px) and (min-width: 1001px) {
    
    .NavBar{
        padding: 0;  
        width: 79.1vw;
        height: 80px;
    }

    .NavWrapper{
        width: 79.1vw;
        height: 80px;

        border-bottom: 2px solid #486ADA;
        margin-right: 0;
    }

    .NavBarConteiner{
        display: none;
    }
     
     .Logo{
        width: 11.9vw;
        height: 2.1vw;

    }

    .NavPanel{
        height: 19px;
    }

    .Points{
        font-size: 0.8vw;
    }

}


@media (max-width: 900px) and (min-width: 481px){
    .NavBar{   
        width: 100vw;
        height: 8vw;
    }
    .Points{
        font-size: 2.1vw;
    }

    .NavWrapper{
       width: 95vw;
       height: 10.4vw;
       border-bottom: 2px solid #486ADA;
    }

    .NavBarConteiner{
        display: none;
    }


    .Logo{
        width: 6.1vw;
        height: 5.2vw;
        background-image: url('./images/LogoSh.svg');
    }

    .NavPanel{
        font-size: 2.1vw;
        gap: 7.8vw;
        height: 2.5vw;
    }
}

@media (max-width: 480px) and (min-width: 321px){
    .NavBar{
        flex-direction: column;  
        width: 92vw;
    }

    .NavBarPage{
        flex-direction: column;  
        width: 100vw;
        height: auto;
        margin-top: 4.2vw;
    }

    .Points{
        color: #FFF;
    }

    .NavWrapper{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
       border-bottom: none;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }

    .NavWrapperPage{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
       border-bottom: none;
       position: absolute;
       top: 0;
       left: 0;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }

    .Logo{
        width: 47.7vw;
        height: 8vw;
    }

    .NavPanel{
        position: absolute;
        top: -266.3vw;
        transition: 1s;
        display: none;
        opacity: 0;
    }

    .NavWrapper.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        position: absolute;
        display: flex;
        transition: 1s;
        width: 120vw;
        height: 200vh;
    }
    .NavWrapperPage.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        position: absolute;
        display: flex;
        transition: 1s;
        top: 0vw;
        left: 0vw;
        width: 120vw;
        height: 200vh;
    }

    .NavBar.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
    }
    .NavBarPage.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
        margin-top: 4.2vw;
    }

    .NavPanel.open-menu{
        opacity: 100;
        position: absolute;
        top: 55vw;
        left: 15vw;
        transition: 1s;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        color: #FFF;
        width: 87.5vw;
        height: 78.1vw;
        font-size: 6.6vw;
    }
}

@media (max-width: 320px){
    .NavBar{
        flex-direction: column;  
        width: 92vw;
    }
    .NavBarPage{
        flex-direction: column;  
        width: 106vw;
        height: 60.6vw;
    }

    .Points{
        color: #FFF;
    }

    .NavWrapper{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
        position: absolute;
        top: 0;
        left: 0;
       border-bottom: none;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }

    .NavWrapperPage{
        background: rgba(72, 106, 218, 0.30);
        backdrop-filter: blur(10.3vw);
        display: none;
        transition: 1s;
       border-bottom: none;
       width: 87.5vw;
       height: 25vw;
       z-index: 100;
    }


    .Logo{
        width: 47.7vw;
        height: 8vw;
    }

    .NavPanel{
        position: absolute;
        top: -266.3vw;
        transition: 1s;
        display: none;
        opacity: 0;
    }

    .NavWrapper.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        display: flex;
        transition: 1s;
        width: 120vw;
        height: 200vh;
    }
    .NavWrapperPage.open-menu{
        padding-left: 6.3vw;
        z-index: 100;
        position: relative;
        display: flex;
        transition: 1s;
        top: -44vw;
        left: -36vw;
        width: 120vw;
        height: 200vh;
    }

    .NavBar.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
    }
    .NavBarPage.open-menu{
        transition: 1s;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
    }

    .NavPanel.open-menu{
        opacity: 100;
        position: absolute;
        top: 55vw;
        left: 15vw;
        transition: 1s;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        color: #FFF;
        width: 87.5vw;
        height: 78.1vw;
        font-size: 6.6vw;
    }
}