.Footer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 431px;
    font-family: 'Inter';
    background: #363638;
    margin-top: 60px;
    background-image: url('./images/AngelsBig.webp');
    background-size: 28%;
    background-repeat: no-repeat;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg); 
}

.PointFooter{
    cursor: pointer;
}

.Footer p{
    margin-top: 0;
}

.FooterWrapper{
    margin-top: 168px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1520px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); 
}

.LogoWrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 357px;
    height: 213px;
    margin-right: 30px;
}
.ComDescript{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 159px;
    height: 111px;
    color: #FFF;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    line-height: 131.523%;
    margin-bottom: 10px;
}
.Ltd{
    width: 229px;
    height: 42px;
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    line-height: 131.523%;
}

.NavWrapp{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 357px;
    height: 151px;
    margin-right: 2vw;
}

.LocationsWrapper{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 357px;
    height: 213px;
    font-size: 16px;
    margin-right: 20px;
}

.NavHeader{
    color: #FFF;
    font-size: 21px;
    line-height: normal;
    margin-bottom: 20px;
}

.LockHeader{
    color: #FFF;
    font-size: 21px;
    line-height: normal;
    margin-bottom: 20px;
}

.Office{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}

.OfHead{
    color: #FFF;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

.Contacts{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 357px;
}

.Cont{
    color: #FFF;
    font-size: 21px;
    line-height: normal;
    margin-bottom: 20px;
}

.ContH{
    color: #FFF;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

.LocationF{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 357px; 
}

.GeoLogoF{
    background-size: 100%;
    margin-right: 10px;
    width:30px;
    height:30px;
    background-image: url("./images/BlueGeo.svg");
    background-repeat: no-repeat;
}

.GeoTextF{
    color: #FFF;
    font-size: 16px;
    line-height: 116.8%;
    margin-left: 0;
    width: 317.5px;
}

.InfoWrapperF{
    margin-bottom: 10px;
    width:357px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.PhoneF{
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 15px;
    width:30px;
    height:30px;
    background-image: url("./images/BluePhone.svg");
}

.PhoneInfoF{
    color: #FFF;
    font-size: 16px; 
    line-height: 116.8%;
    margin-bottom:0;
}

.NumbersInfoF{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 357px;
    height: 70px;
}

.LogoF{
    background-image: url('./images/LogoWF.svg');
    width: 225px;
    height: 50px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

#Footer{
    flex-direction: column;
    width:22.5vw;
    height:10.6vw;
    gap: 10px;
}

@media (max-width: 1000px) and (min-width: 769px){
    .Footer{
        width: 100vw;
        height: 43.1vw;
        margin-top: 60px;
        background-image: url('./images/Angels.webp');
        background-size: 52%;
    }

    
    .FooterWrapper{
        margin-top: 14.4vw;
        padding: 0 2vw;
        width: 96vw;
        height: 23.7vw;
    }
    
    .LogoWrapper{
        width: 22.5vw;
        height: 23.7vw;
        margin-right: 2vw;
    }
    
    .ComDescript{
        font-size: 1.3vw;
        width: 15.9vw;
        height: 11.1vw;
        margin-bottom: 1vw;
    }
    .Ltd{
        font-size: 1.6vw;
        width: 22.5vw;
        height: 4.2vw;
    }
    
    .NavWrapp{
        width: 22.5vw;
        height: 23.7vw;
        margin-right: 2vw;
    }
    
    .LocationsWrapper{
        width: 22.5vw;
        height: 23.7vw;
        font-size: 1.6vw;
        margin-right: 2vw;
    }
    
    .NavHeader{
        font-size: 2.1vw;
        margin-bottom: 2vw;
    }
    
    .LockHeader{
        font-size: 2.1vw;
        margin-bottom: 2vw;
    }
    
    .Office{
        margin-bottom: 1vw;
    }
    
    .OfHead{
        font-size: 1.6vw;
        margin-bottom: 1vw;
    }
    
    .Contacts{
        width: 23.7vw;
    }
    
    .Cont{
        font-size: 2.1vw;
        margin-bottom: 0;
    }
    
    .ContH{
        font-size: 1.6vw;
        margin-bottom: 0;
    }
    
    .LocationF{
        width: 62.5vw; 
    }
    
    .GeoLogoF{
        margin-right: 1vw;
        width:3vw;
        height:3vw;
    }

    .LogoF{
        width: 22.5vw;
        height: 5vw;
    }
    
    .NumWrappFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .GeoTextF{
        font-size: 1.6vw;
        margin-left: 0;
        width: 16.5vw;
    }
    
    .InfoWrapperF{
        margin-bottom: 0;
        width:22.5vw;
        height: 6.7vw;
    }
    
    .ConnectFoot{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }
    .PhoneF{
        margin-right: 1.5vw;
        width:3vw;
        height:3vw;
    }
    
    .PhoneInfoF{
        font-size: 1.6vw; 
        margin-bottom:0;
    }
    
    .NumbersInfoF{
        width: 22.5vw;
        height: 9.5vw;
    }
}

@media (max-width: 1920px) and (min-width: 1001px){

        .Footer{
            height: 22.4vw;
            margin-top: 60px;
            background-image: url('./images/AngelsBlue.png');
            background-size: 33%;
        }
        
    
        .FooterWrapper{
            width: 79.1vw;
            margin-top: 5.8vw;
            padding: 0;
        }
        
        .LogoWrapper{
            align-items: flex-start;
            width: 18.6vw;
            height: 12.9vw;
            margin-right: 10.4vw;
        }

        .ComDescript{
            font-size: 0.7vw;
            width: 8.6vw;
            height: 5.8vw;
            margin-bottom: 0.5vw;
        }
    
        .Ltd{
            font-size: 0.8vw;
            width: 11.9vw;
            height: 2.9vw;
        }
        .NavWrapp{
            width: 18.6vw;
            height: 7.9vw;
            margin-right: 1.6vw;
        }
    
        .LocationsWrapper{
            width: 18.6vw;
            height: 11.9vw;
            font-size: 0.8vw;
            margin-right: 1.6vw;
        }
    
        .NavHeader{
            font-size: 1.1vw;
            margin-bottom: 1vw;
        }
    
        .LockHeader{
            font-size: 1.1vw;
            margin-bottom: 1vw;
        }
        
        .Office{
            margin-bottom: 0.5vw;
        }
        
        .OfHead{
            font-size: 0.8vw;
            margin-bottom: 0.5vw;
        }
    
        .Contacts{
            width: 18.6vw;
            height: 11.9vw;
        }
    
        .Cont{
            font-size: 1.1vw;
            margin-bottom: 1vw;
        }
    
        .ContH{
            font-size: 0.8vw;
            margin-bottom: 0.5vw;
        }

        .LocationF{
            width: 18.6vw; 
        }
        
        .GeoLogoF{
            margin-right: 0.8vw;
            width:1.8vw;
            height:1.6vw;
        }

        .LogoF{
            width: 11.7vw;
            height: 2.6vw;
        }
        
        
        .GeoTextF{
            font-size: 0.8vw;
            margin-left: 0;
            width: 18.6vw;
        }

        .InfoWrapperF{
            margin-bottom: 0.5vw;
            width: 18.6vw;
            height: 1.6vw;
        }
        
        .PhoneF{
            margin-right: 0.8vw;
            width:1.6vw;
            height:1.6vw;
        }
        
        .PhoneInfoF{
            font-size: 0.8vw; 
            margin-bottom:0;
        }
        
        .NumbersInfoF{
            width: 18.6vw;
            height: 3.6vw;
        }


  
}

@media (max-width: 768px) and (min-width: 481px){
    .Footer{
        width: 100vw;
        height: 76.9vw;
        margin-top: 60px;
        background-size: 102%;
    }

    .LogoF{
        width: 29.2vw;
        height: 6.5vw;
    }
    
    .FooterWrapper{
        flex-direction: column;
        margin-top: 6.5vw;
        padding: 0;
        width: 95vw;
    }
    
    .LogoWrapper{
        flex-direction: row;
        width: 95vw;
        height: 16.4vw;
        margin-right: 0;
    }

    .ComDescript{
        font-size: 2vw;
        width: 25.2vw;
        height: 16.4vw;
        margin-right: 0;
    }
    
    .Ltd{
        font-size: 2vw;
        width: 29.2vw;
        height: 16.4vw;
    }
    
    .NavWrapp{
        width: 95vw;
        height: 7vw;
        margin-right:0;
        margin-top: 3.9vw;
    }

    #Footer{
        flex-direction: row;
        width: 95vw;
    }
    
    .LocationsWrapper{
        margin-top: 3.9vw;
        width: 95vw;
        height: 12.2vw;
        font-size: 2.1vw;
        margin-right: 0;
    }
    
    .NavHeader{
        font-size: 2.7vw;
        margin-bottom: 1.3vw;
    }
    
    .LockHeader{
        font-size: 2.7vw;
        margin-bottom: 1.3vw;
    }
    
    .NumWrappFoot{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .LockWrapFoot{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ConnectFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .Office{
        margin-bottom: 1.3vw;
    }
    
    .OfHead{
        font-size: 2.1vw;
        margin-bottom: 1.3vw;
    }
    
    .Contacts{
        margin-top: 3.9vw;
        width: 95vw;
    }
    
    .Cont{
        font-size: 2.7vw;
        margin-bottom: 2.6vw;
    }
    
    .ContH{
        font-size: 2.1vw;
        margin-bottom: 1.3vw;
    }
    
    .LocationF{
        width: 46.1vw; 
    }
    
    .GeoLogoF{
        width:3.9vw;
        height:3.9vw;
        margin-right: 0;
    }
    
    .GeoTextF{
        font-size: 2.1vw;
        margin-left: 1.3vw;
        width: 32.7vw;
    }
    
    .InfoWrapperF{
        width:46.1vw;
    }
    
    .PhoneF{
        margin-right: 1.3vw;
        width:3.9vw;
        height:3.9vw;
    }
    
    .PhoneInfoF{
        font-size: 2.1vw; 
        margin-bottom:0;
    }
    
    .NumbersInfoF{
        width: 46.1vw;
        height: 9.1vw;
    }
    
}

@media (max-width: 480px) and (min-width: 321px){
    .Footer{
        width: 100vw;
        height: 210vw;
        margin-top: 60px;
        background-size: 89%;
        background-position-y: 100%;
    }
    

    
    .LogoF{
        width: 46.9vw;
        height: 10.4vw;
    }

    .FooterWrapper{
        flex-direction: column;
        margin-top: 10.4vw;
        padding: 0;
        width: 92vw;
    }
    
    .LogoWrapper{
        flex-direction: column;
        width: 92vw;
        height: 48.9vw;
        margin-right: 0;
    }
    
    .ComDescript{
        font-size: 3.3vw;
        width: 92vw;
        height: 25.1vw;
    }
    .Ltd{
        font-size: 3.3vw;
        width: 92vw;
        height: 4.4vw;
    }
    
    .NavWrapp{
        width: 92vw;
        height: 34.4vw;
        margin-right:0;
        margin-top: 6.3vw;

    }

    #Footer{
        display: flex;
        opacity: 1;
        flex-direction: column;
        width: 92vw;
        position:unset;
    }
    
    .LocationsWrapper{
        margin-top: 6.3vw;
        width: 95vw;
        height: 38.4vw;
        font-size: 4.4vw;
        margin-right: 0;
    }
    
    .NavHeader{
        font-size: 4.4vw;
        margin-bottom: 2.1vw;
    }
    
    .LockHeader{
        font-size: 4.4vw;
        margin-bottom: 2.1vw;
    }
    
    .NumWrappFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .LockWrapFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ConnectFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .Office{
        margin-bottom: 2.1vw;
    }
    
    .OfHead{
        font-size: 3.3vw;
        margin-bottom: 2.1vw;
    }
    
    .Contacts{
        margin-top: 3.9vw;
        width: 92vw;
    }
    
    .Cont{
        font-size: 4.4vw;
        margin-bottom: 4.2vw;
    }
    
    .ContH{
        font-size: 3.3vw;
        margin-bottom: 2.1vw;
    }
    
    .LocationF{
        width: 92vw; 
    }
    
    .GeoLogoF{
        width:6.3vw;
        height:6.3vw;
        margin-right: 0;
    }

    .LogoF{
        width: 70.3vw;
        height: 15.6vw;
    }
    
    .GeoTextF{
        font-size: 3.3vw;
        margin-left: 2.1vw;
        width: 83.3vw;
    }
    
    .InfoWrapperF{
        width:83.3vw;
    }
    
    .PhoneF{
        margin-right: 2.1vw;
        width:6.3vw;
        height:6.3vw;
    }
    
    .PhoneInfoF{
        font-size: 3.3vw; 
        margin-bottom:0;
    }
    
    .NumbersInfoF{
        align-items: flex-start;
        width: 92vw;
        height: 18.6vw;
    }
    
}

@media (max-width: 320px){
    .Footer{
        width: 100vw;
        height: 290vw;
        margin-top: 60px;
        background-image: none;

    }


    
    .FooterWrapper{
        flex-direction: column;
        margin-top: 9.4vw;
        padding: 0;
        width: 87.5vw;
    }

    .PointFooter{
        font-size:5vw;
        font-family: 'Inter';
        font-style: normal;
        line-height: normal;
    }
    
    .LogoWrapper{
        flex-direction: column;
        width: 87.5vw;
        height: 81.2vw;
        margin-right: 0;
    }
    
    
    .Ltd{
        font-size: 5vw;
        width: 87.5vw;
        height: 13vw;
    }
    
    .NavWrapp{
        width: 87.5vw;
        height: 44.1vw;
        margin-right:0;
        margin-top: 9.4vw;

    }

    #Footer{
        display: flex;
        opacity: 1;
        flex-direction: column;
        width: 87.5vw;
        height: 44.1vw;
        position:unset;
        font-size:5vw;
        font-family: 'Inter';
        font-style: normal;
        line-height: normal;
    }
    
    .LocationsWrapper{
        margin-top: 9.4vw;
        width: 87.5vw;
        height: 53.6vw;
        font-size: 6.6vw;
        margin-right: 0;
    }
    
    .NavHeader{
        font-size: 6.6vw;
        margin-bottom: 3vw;
    }
    
    .LockHeader{
        font-size: 6.6vw;
        margin-bottom: 6.3vw;
    }
    
    .NumWrappFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .LockWrapFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ConnectFoot{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .Office{
        margin-bottom: 3vw;
    }
    
    .OfHead{
        font-size: 5vw;
        margin-bottom: 3vw;
    }
    
    .Contacts{
        margin-top: 9.4vw;
        width: 92vw;
    }
    
    .Cont{
        font-size: 6.6vw;
        margin-bottom: 4.2vw;
    }
    
    .ContH{
        font-size: 5vw;
        margin-bottom: 3vw;
    }
    
    .LocationF{
        width: 87.5vw; 
    }
    
    .GeoLogoF{
        width:6.3vw;
        height:6.3vw;
        margin-right: 0;
    }
    
    .GeoTextF{
        font-size: 5vw;
        margin-left: 2.1vw;
        width: 83.3vw;
    }
    
    .InfoWrapperF{
        width:83.3vw;
    }
    
    .PhoneF{
        margin-right: 2.1vw;
        width:6.3vw;
        height:6.3vw;
    }
    
    .PhoneInfoF{
        font-size: 5vw; 
        margin-bottom:0;
    }
    
    .NumbersInfoF{
        align-items: flex-start;
        width: 87.5vw;
        height: 18.6vw;
    }
}
