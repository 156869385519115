.CopieWrapp{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #48DA82;
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    border-radius: 2vw;
}

:root {
    --toastify-color-light:#48DA82
}

.Toastify__toast-theme--light {
    border-radius: 20px;
}
.Toastify__toast-container {
    width: 280px;

}



@media (max-width: 320px){
    .Toastify__toast-container {
        width: 87.5vw;
        margin-left: 6.3vw;
        margin-top: 15px;
    
    }

    .CopieWrapp{
        border-radius: 6.3vw;
    }

    .Toastify__toast-theme--light {
        border-radius: 6.3vw;
    }
}

@media (max-width: 480px) and (min-width: 321px){
    .Toastify__toast-container {
        width: 87.5vw;
        margin-left: 6.3vw;
        margin-top: 15px;
    }

    .Toastify__toast-theme--light {
        border-radius: 6.3vw;
    }

    .CopieWrapp{
        border-radius: 6.3vw;
    }
}

@media (max-width: 768px) and (min-width: 481px){
    .Toastify__toast-container {
        width: 40vw;
    
    }
}

