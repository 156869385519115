.ThirdBlock{
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.LinkTo{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 20px;
    width: 245px;
    height: 55px;
    color: #486ADA;
    flex-wrap: wrap;
    line-height: normal;
    text-decoration: none;

    overflow: visible;
}

.LinkTo img{
    width: 39px;
    height: 24px;
}

.LinkTo p{
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 5px;
}

.LinkTo:hover{
    gap: 40px;
}

.LinkTo:active{
    color: #363638;
}

.LinkToMob{
    margin-top: 60px;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 92vw;
    height: 7.2vw;
    color: #486ADA;
    line-height: normal;
    text-decoration: none;

}

.LinkToMob img{
    width: 39px;
    height: 24px;
}

.LinkToMob p{
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 5px;
}

.ThirdWrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.ThirdHeader{
    font-family: 'Inter-Bold';
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 1518px;
    height: 55px;
    color: #363638;
    font-size: 50px;
    line-height: 110.523%;
}

.ThirdHeader p{
    font-family: 'Inter';
}

@media (max-width: 1920px){
    .ThirdBlock{
        margin-top: 7.8vw;
        padding: 0;
    }

    .ThirdHeader{
        width: 79.1vw;
        height: 2.9vw;
        font-size: 2.6vw;
    }

    
}

@media (max-width: 1000px){
    .ThirdBlock{
        margin-top: 10vw;
        padding: 2vw;
    }
    
    .ThirdHeader{
        width: 96vw;
        height: 5.5vw;
        font-size: 5vw;
    }
}

@media (max-width: 768px){
    .ThirdBlock{
        margin-top: 10vw;
    }

    .LinkToMob{
        margin-top: 30px;
    }

    .LinkTo{
        display: none;
    }

    
    .LinkToMob{
        display: Flex;
    }

    .ThirdWrapper{
        align-items: center;
    }
    
    .ThirdHeader{
        justify-content: center;
        width: 95vw;
        height: 7.2vw;
        font-size: 6.5vw;
    }
}

@media (max-width: 480px){
    .ThirdBlock{
        margin-top: 16.7vw;
    }

    .ThirdWrapper{
        align-items: center;
    }
    .LinkToMob{
        margin-top: 30px;
    }
    
    .ThirdHeader{
        width: 83.3vw;
        height: 11.5vw;
        font-size: 9.4vw;
    }
}
@media (max-width: 320px){
    .ThirdBlock{
        margin-top: 25vw;
    }

    .LinkToMob{
        margin-top: 30px;
    }
    .ThirdWrapper{
        align-items: center;
    }
    
    .ThirdHeader{
        width: 83.3vw;
        height: 10.3vw;
        font-size: 9.4vw;
    }
}