.RSS{
    height: 268.8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 100px;
    font-family: "Inter";
}

.RSSHeader{
    width: 1518px;
    height: 55px;
    color: #363638;
    font-size: 50px;
    line-height: 110.523%;
    font-family: "Inter-Bold";
}

.RSSwrapp{
  height: 90px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ShotR{
  height: 90px;
  margin-right: 90px;
  background-size: contain;
  background-repeat: no-repeat;
}

.Gay{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Cal{
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rfm-child:hover .Cal{
  cursor: pointer;
  display: flex;
}

.rfm-child:hover .Gay{
  display: none;
}

.marqueeOuter {
    margin-top: 100px;
    width: 100vw;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

}
  
  .marqueeInnerVisible {
    animation-name: marqueeVis;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

 @keyframes marqueeVis {
    0% {
      left: 0%;
    }
  
    100% {
      left: -208%;
    }
  }

 
    
  .marqueeInnerVisible:hover{
    animation-play-state: paused;
  }

@media (max-width: 1920px){

.RSS{
  margin-top: 5.8vw;
  height: 17vw;
  padding: 0;
}

.RSSHeader{
  width: 79.2vw;
  height: 2.9vw;
  font-size: 2.6vw;
}

.marqueeOuter {
  margin-top: 5.4vw;
}


@keyframes marqueeVis {
  0% {
    left: -80.5vw;
  }

  100% {
    left: -208%;
  }
}
}

@media (max-width: 1000px){
  .RSS{
    height: 28.5vw;
    margin-top: 8vw;
}

.RSSHeader{
    width: 96vw;
    height: 5.5vw;
    font-size: 5vw;
}

.RSSwrapp{
  height: 9vw;
  width: 100vw;
}

.ShotContainer{
    width: 100vw;
    height: 9vw;
}

.marqueeOuter {
    width: 100vw;
    margin-top: 10vw;
}

 @keyframes marqueeVis {
    0% {
      left: 0%;
    }
  
    100% {
      left: -208%;
    }
  }

}

@media (max-width: 768px){
  .RSS{
    align-items: center;
    height: 38.3vw;
    margin-top: 6vw;
}

.RSSHeader{
  text-align: center;
    width: 95vw;
    height: 7.2vw;
    font-size: 6.5vw;
}

.RSSwrapp{
  height: 11.7vw;
  width: 100vw;
}


.ShotContainer{
    width: 100vw;
    height: 11.7vw;
}

.marqueeOuter {
    width: 100vw;
    margin-top: 13.4vw;
}

 @keyframes marqueeVis {
    0% {
      left: 0%;
    }
  
    100% {
      left: -208%;
    }
  }
}

@media (max-width: 480px){
  .RSS{
    align-items: center;
    height: 70.2vw;
    margin-top: 14.7vw;
}

.RSSHeader{
  text-align: center;
    width: 92vw;
    height: 22.9vw;
    font-size: 10.4vw;
}

.RSSwrapp{
  height: 18.8vw;
  width: 100vw;
}

.ShotContainer{
    width: 100vw;
    height: 18.8vw;
}

.marqueeOuter {
    width: 100vw;
    margin-top: 15.5vw;
}

 @keyframes marqueeVis {
    0% {
      left: 0%;
    }
  
    100% {
      left: -208%;
    }
  }
}

@media (max-width: 320px){
  .RSS{
    align-items: center;
    height: 78.7vw;
    margin-top: 20vw;
}

.RSSHeader{
  text-align: center;
    width: 87.5vw;
    height: 10.3vw;
    font-size: 9.4vw;
}

.RSSwrapp{
  height: 28vw;
  width: 100vw;
}

.ShotContainer{
    width: 100vw;
    height: 28vw;
}

.marqueeOuter {
    width: 100vw;
    margin-top: 32.3vw;
}

 @keyframes marqueeVis {
    0% {
      left: 0%;
    }
  
    100% {
      left: -208%;
    }
  }
}