.Techs{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}
.slider-wrapper.axis-horizontal{
        overflow: hidden;
        border-radius: 40px;
    }
.carousel.slide{
        min-width: 100.1%!important
}
.TechnicWrapp{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    width: 1518px;
    height: max-content;

    gap:  30px 30px;
    color: #363638;

    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    line-height: normal;
}

.Title{
    font-family: "Inter-Bold";
    height: 72px;
}

.Techs{
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 150px;
    gap: 50px;

    
}

.BackButton{
    color: #486ADA;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: 151px;
    gap: 20px;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    line-height: normal;
}

.BackButton img{
    width: 39px;
    height: 24px;
}

.BackButton p{
    margin-top: 0;
    margin-bottom: 2px;
}

.BackButton:hover{
    gap: 40px;   
}
.BackButton:active{
    color: #363638;
}

.TechLi{
    color: #363638;
    font-size: 20px;
    font-family: 'Inter';
    font-style: normal;
    line-height: normal;

}

.Img{
        position: relative;
    overflow-y: hidden;
    scrollbar-width: none;
    width: 446px;
    height: 446px;

}

.Img img{
    width: auto;
    height: 100%;

}
::-webkit-scrollbar {
    width: 0;
  }


.PaginationWrapp{
    display: flex;
    position: absolute;
    left: 126px;
    top: 406px;
    align-items: center;
    justify-content: space-between;
   
 
}
.control-dots{
    display: none;
    gap: 10px;
    width: 94px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 32px!important;
}

.NextButt{
    display: none;
    border: none;
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 70px;
    bottom: 30px!important;
    justify-content: center;
    align-items: center;
    background-color: #486ADA;
    border-radius: 14px;
    z-index: 20;
}


.ImgConteiner{
    height: auto;
    overflow: hidden;
    border-radius: 40px;
    width: 446px;
    height: 446px;
}
#NextImg{
    width: 8px;
    height: 16px;
    transform: rotate(180deg);
}

.PrevButt{
    display: none;
    cursor: pointer;
    border: none;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 70px;
    bottom: 30px!important;
    justify-content: center;
    align-items: center;
    background-color: #486ADA;
    border-radius: 14px;
    z-index: 20;
}

.Img li{
        list-style: none;
}



#PrevImg{
    width: 8px;
    height: 16px; 
}

.carousel span{
    border: 10px solid ;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}
.carousel.carousel-slider{
    overflow: visible !important;
}

.carousel.carousel-slider{
    display: flex;
    justify-content: center;
    align-items: center;
}

.PrevSlide{
    cursor: pointer;
}

.NextSlide{
    cursor: pointer;
}

.carousel.carousel-slider:hover .NextButt{
   display: flex;
    
}

.carousel.carousel-slider:hover .PrevButt{
    display: flex;
}

.carousel.carousel-slider:hover .control-dots{
    display: flex;

}


.SliderWrapper{
    height: 446px;
    width: 446px;
}


.SliderWrapper img{
    width: auto;
    justify-content: flex-start;
    height: 446px;
    width: 446px;


}

.CardWrapper{
    flex:  0 1;
    flex-basis: 20%;
    position: relative;
    width: 486px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    gap: 30px;
    padding: 20px;
    padding-bottom: 35px;
    border-radius: 60px;
    border: 2px solid #486ADA;

}

.contentWrapp{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;

}


.BodyWrapp ul{
    list-style: outside;
    padding-left: 32px;
  
}
.More{
    display: none;
    justify-content: center;
    align-items: center;
    color: #FFF;

    font-family: 'Inter';
    font-size: 21px;
    font-style: normal;
    line-height: normal;
    border-radius: 40px;
    background: #486ADA;
    width: 446px;
    height: 65px;
    gap: 20px;



}



.BBody{
    color: #363638;
    font-size: 21px;
    font-family: "Inter";
    font-style: normal;
    line-height: 118.023%;

    
}


@media only screen and (max-width: 1920px){

        .slider-wrapper.axis-horizontal{
                overflow: hidden;
                border-radius: 2.1vw;
            }

        .ImgConteiner{
                border-radius: 3.1vw;
                width: 23.2vw;
                height: 23.2vw;
        }
    .control-dots{
            position: absolute;   
    }
    .CardWrapper{
         width: 25.3vw;
         gap: 1.6vw;
         padding: 1vw;
         padding-bottom: 1.5vw;
         border-radius: 3.1vw;
    }
    .BBody{
        font-size: 1.1vw;
    }
    .More{

            gap: 1vw;
            width: 23.2vw;
            height: 3.4vw;
            border-radius: 2.1vw;
            font-size: 1.1vw;
       
    }
    .BodyWrapp ul{
            padding-left: 1.7vw;

       
    }
    .contentWrapp{
            gap: 0.8vw;
            padding-bottom: 20px;
    }
    .SliderWrapper img{
            height: 23.2vw;
            width: 23.2vw;

    }
    .SliderWrapper{
        
            height: 23.2vw;
            width: 23.2vw;

    }
    .PrevButt{
            left: 1vw;
    }
    .NextButt{
            right: 1vw;
    }
    .Img{
            width: 23.2vw;
            height: 23.2vw;
        }

        .TechLi{
                font-size: 1vw;
        }
        
        .TechnicWrapp{
                width: 79.1vw;
                font-size: 1.6vw;
                gap:  1.6vw 1.6vw;
        }

}
@media only screen and (max-width: 1000px){
        .slider-wrapper.axis-horizontal{
                overflow: hidden;
                border-radius: 4vw;
            }
        .ImgConteiner{
                border-radius: 6vw; 
                width: 42vw;
                height: 42vw;
        }
    .Techs{
        gap: 50px;
    }
    .control-dots{
            position: absolute;
    }
    .CardWrapper{
            width: 47vw;
            gap: 3vw;
            padding: 2vw;
            border-radius: 6vw;
               
    }
    .BBody{
        font-size: 2.1vw;
    }
    .More{
            gap: 2vw;
            width: 43vw;
            height: 6.5vw;
            border-radius: 4vw;
            font-size: 2.1vw;
     
    }
    .BodyWrapp ul{

            padding-left: 3.2vw;

      
    }
    .contentWrapp{
            gap: 1.5vw;
            padding-bottom: 20px;
    }
    .SliderWrapper img{
            height: 42.5vw;
            width: 42.5vw;
    }
    .SliderWrapper{
            height: 42.5vw;
            width: 42.5vw;

    }
    .PrevButt{
            left: 7vw;
    }
    .NextButt{
            right: 7vw;
    }
    .Img{
            width: 42.5vw;
            height: 42.5vw;

    }

    .TechLi{
            font-size: 2vw;

    }
    .Techs{
        margin-top: 100px;
        padding: 0 2vw;
}
.TechnicWrapp{
        font-size: 3vw;
        gap: 3vw 3vw;
        width: 96vw;
        grid-template-columns: repeat(2, 1fr);
}

}
@media only screen and (max-width: 768px){
        
.BackButton:hover{
        gap: 20px;   
    }
    .control-dots{
            display: flex;
            top: 46vw;
            position: absolute;
    }

    .CardWrapper{
            width: 45.5vw;
            gap: 3vw;
            padding: 2vw;
            padding-bottom: 3vw;
            border-radius: 7.8vw;
    }
    .BBody{
        font-size: 2.7vw;
    }
    .BodyWrapp ul{

            padding-left: 4.2vw;

    }
    .contentWrapp{
            gap: 2vw;
            padding-bottom: 20px;

    }
    .SliderWrapper{
            height: 50vw;
            width: 41vw;

    }
    .PrevButt{
            display: flex;
            left: 2.5vw;
            top: 44.5vw;
            width: 5.2vw;
            height: 5.2vw;
            border-radius: 1.8vw;
    }

    .carousel span{
        width: 2.1vw;
        height: 2.1vw;
    }

    .slide{
        margin-right: 15px
    }
    .ImgConteiner{
        width: 40.9vw;
        height: 41vw;
        overflow: clip;
        border-radius: 5.2vw;

    }
    .slider-wrapper.axis-horizontal{
        overflow: hidden;
        border-radius: 5.2vw;
    }
    .NextButt{
            right: 2.5vw;
            display: flex;
            top: 44.5vw;
            bottom: auto;
            width: 5.2vw;
            height: 5.2vw;
            border-radius: 1.8vw;
    }
    .Img img{
            width: auto;
            height: 50vw;
            background: none;

    }

    .control-dots{
        gap: 1.3vw;
        width: 12.2vw;
    }



    .Img{
            width: 41vw;
            height: 50vw;
            background: none;
    

    }
    .TechLi{
            font-size: 2.6vw;

    }
    .TechnicWrapp{
            font-size: 3.6vw;
     
    }

}
@media  only screen and (max-width: 480px){
    .Techs{
        gap: 20px;
    }
    .control-dots{
            display: flex;
            z-index: 20;
            top: 87vw;
            height: 20px;
            position: absolute;
            
    }
    .CardWrapper{
            width: 92vw;
            gap: 6.3vw;
            padding: 4.2vw;
            padding-bottom: 5.5vw;
            border-radius: 12.5vw;
    }
    .BBody{
        font-size: 4.4vw;
    }
    .More{
            gap: 4.2vw;
            width: 83.3vw;
            height: 13.5vw;
            border-radius: 8.3vw;
            font-size: 4.4vw;

    }
    .BodyWrapp ul{
            padding-left: 5vw;
    }
    .contentWrapp{
            gap: 3.1vw;
            padding-bottom: 20px;
    }
    .SliderWrapper img{
            height: 83vw;
            width: 83vw;
    }
    .SliderWrapper{
            height: 83vw;
            width: 83vw;
    }
    .PrevButt{
            left: 10.6vw;
            display: flex;
            top: 86.5vw;
            width: 40px;
            height: 40px;
            border-radius: 14px;
    }
    .ImgConteiner{
        border-radius: 8.3vw;
            height: 83vw;
            width: 83vw;
            overflow: clip;
            isolation: isolate;
    }
    .slider-wrapper.axis-horizontal{
        z-index: 0;
        overflow: hidden;
        border-radius: 8.3vw;
    }
    .NextButt{
            display: flex;
            right: 10.6vw;
            top: 86.5vw;
            bottom: auto;
            width: 40px;
            height: 40px;
            border-radius: 14px;
    }
    .Img img{
            width: 100%;
            height: 83.3vw;

    }
    .Img{
            border: none;
        
            width: 83vw;
            height: 100vw;;
    }
    .TechLi{
            font-size: 4.4vw;

    }
    .BackButton{
        width: 131px;
    }
    .Techs{
            margin-top: 55px;
            padding: 0 20px;
    }
    .TechnicWrapp{
            width: 92vw;
            gap: 4.2vw;
            font-size: 6.3vw;
            grid-template-columns: repeat(1, 1fr);
    }
}


@media only screen and (max-width: 320px){
        .slider-wrapper.axis-horizontal{
                overflow: hidden;
                border-radius: 9.4vw;
            }
    .CardWrapper{
        border-radius: 15.6vw;
    }

    .Title{
        height: 15.5vw;
    }
    .BodyWrapp ul{

            padding-left: 6vw;

    }
    .contentWrapp{
            gap: 4.7vw;
            padding-bottom: 20px;
    }
    
    .PrevButt{
            left: 2.6vw;
            top: 86.5vw;
    }
    .NextButt{
            display: flex;
            right: 2.6vw;
            top: 86.5vw;
    }
    .TechLi{
            font-size: 4vw;
    }
}

