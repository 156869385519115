.ButtonTopWrapp{
    position: fixed;
    transition: 0.3s;
    right: 70px;
    top: 86.5vh;
    width: 66px;
    height: 66px;
    border-radius: 66px;
    background: rgba(72, 106, 218, 0.20);

    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 99;
}

.ButtonTopWrapp:hover{
    background: rgba(72, 106, 218, 1);
}

@media (max-width: 1920px){
    .ButtonTopWrapp{
        bottom: 48.6vw;
    }
    
}


@media (max-width: 1000px){
    .ButtonTopWrapp{
        top: auto;
        right: 10px;
        bottom: 13.5vh;
    }
    
}

@media (max-width: 768px){
    .ButtonTopWrapp{
        right: 10px;
        bottom: 48.6vw;
    }
    .ButtonTopWrapp:hover{
        background: rgba(72, 106, 218, 0.20);
    }
    
}


@media (max-width: 480px){
    .ButtonTopWrapp{
        right: 10px;
        bottom: 10px;
    }
    
    
}


@media (max-width: 320px){
    .ButtonTopWrapp{
        width: 50px;
        height: 50px;
        right: 10px;
        bottom: 10px;
    }
}