.Slider::-webkit-scrollbar {
    display: none;
  }
   
.Slider{
    width: 402.1vw;
    height: 122.8vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
    overflow-y: unset;
    padding-left: 4.2vw;
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
    font-family: "Inter";
}

.SliderWrapper{
    width: 402.1vw;
    height: 111.9vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    -webkit-overflow-scrolling: none;
}

.Slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6.3vw;
    background: #486ADA;
    width: 77.1vw;
    margin-right: 4.2vw;
}


@media (max-width: 320px){
    .Slider{
        width: 431.3vw;
        height: 154.2vw;
        padding-left: 6.3vw;
    }
    
    .SliderWrapper{
        width: 431.1vw;
        height: 170.2vw;
    }
    
    .Slide{
        border-radius: 6.3vw;
        width: 81.3vw;
        margin-right: 6.3vw;
    }
}
