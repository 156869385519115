.Form{
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    margin-top: 150px;
    font-family: 'Inter';


}

.FormBlock{
    overflow: visible;
}



.DropDownButt:hover{
    cursor: pointer;
    padding-bottom: 10px;


}

.Modal{

    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 30px 50px;
    gap: 50px;
    width: 731px;
    border-radius: 30px;
    background: #FFF;
    font-family: 'Inter';
    font-style: normal;


    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 16px 0px rgba(0, 0, 0, 0.15), 0px 29px 29px 0px rgba(0, 0, 0, 0.13), 0px 66px 40px 0px rgba(0, 0, 0, 0.08), 0px 117px 47px 0px rgba(0, 0, 0, 0.02), 0px 183px 51px 0px rgba(0, 0, 0, 0.00);


}

.Modal.err{
    z-index: 100;
    border: 1px solid #DA4848;
}



.TextContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    
}

.MHeader{
    text-align: center;
    color: #373737; 
    font-family: 'Inter-Bold';
    font-size: 35px;
    font-style: normal;
    line-height: 118.023%; 
   
    

}

.MBody{
    text-align: center;
    color: #373737;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    line-height: 118.023%;

}

.Ok{
    border: none;
    border-radius: 20px;
    background: #486ADA;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    font-family: 'Inter';
    font-size: 21px;
    font-style: normal;
    line-height: 118.023%;



}

.Ok:hover{
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 16px 0px rgba(0, 0, 0, 0.15), 0px 29px 29px 0px rgba(0, 0, 0, 0.13), 0px 66px 40px 0px rgba(0, 0, 0, 0.08), 0px 117px 47px 0px rgba(0, 0, 0, 0.02), 0px 183px 51px 0px rgba(0, 0, 0, 0.00);
}

.Ok:active{
    background: #363638;
}


.ForModal.Open{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    top: 0%;
    left: 0;
    background: rgba(72, 106, 218, 0.30);

    backdrop-filter: blur(33px);
}

.FormHeader{

    color: #363638;
    font-family: 'Inter-Bold';
    font-size: 50px;
    font-style: normal;
    line-height: 110.523%;

}

.FormWrapp{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    flex-direction: row;

}

.FIOWrapp{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
}

.FIO{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    gap: 30px;
}

.Fio{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.NameIn{
    border-radius: 20px;
    border: 2px solid #486ADA;

    width: 456px;
    height: 55px;
    padding: 15px;
    color: #373737;
    font-family: 'Inter';
    font-size: 21px;

}


.TelIn{
    border-radius: 20px;
    border: 2px solid #486ADA;
    width: 258px;
    height: 55px;
    padding: 15px;
    
    color: #373737;
    font-family: 'Inter';
    font-size: 21px;

}


.Lab{
    color: #373737;

    padding: 10px 15px;
    font-family: "Inter-Bold";

    font-size: 21px;
    font-style: normal;
    line-height: 118.023%; 
}






.SelectorWrapp{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DownWrapp{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #363638;
    width: 35px;
    height: 35px;
}

.Down{
    width: 16px;
    height: 8px;
}

input:focus{
    outline: none;
    background: rgba(72, 106, 218, 0.10);
}

 .DownWrapp:hover{
    transform: rotate(-180deg);
  }
textarea{
    outline: none;
}

textarea:focus{
    background: rgba(72, 106, 218, 0.10);
}



.SelectorVac li{
    color: #373737;
    font-family: 'Inter';
    font-size: 21px;
}


.VacChange{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.AboutYourself{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.LabFix{
    resize: none;
    width: 744px;
    height: 284px;
    padding: 15px;
    border-radius: 20px;
    border: 2px solid #486ADA;
    color: #373737;
    font-family: 'Inter';
    font-size: 21px;
}

.Submit{
    font-family: "Inter";
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 20px;
    color: #FFF;
    border: none;
    gap: 10px;

    padding: 15px 13px;
    font-size: 21px;
    width: 194px;
    background: #486ADA;
    height: 55px;
}


.Submit p{
    margin-top: 0;
    margin-bottom: 3px;
}

.Submit img{
    width: 39px;
    height: 24px;
}
.Submit:hover{
    gap: 20px;
    padding-right: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 16px 0px rgba(0, 0, 0, 0.15), 0px 29px 29px 0px rgba(0, 0, 0, 0.13), 0px 66px 40px 0px rgba(0, 0, 0, 0.08), 0px 117px 47px 0px rgba(0, 0, 0, 0.02), 0px 183px 51px 0px rgba(0, 0, 0, 0.00);
}
.Submit:active{
    background: #363638;
}
.Attach{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;
}
.Attachment{
    top: 40%;
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
 
}

.input__file-icon{
    width: 29px;
    height: 29px;
}

.Attach:active .input__file-icon-wrapper{
    background: #363638;
}

.Attach:hover .input__file-icon-wrapper{
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 16px 0px rgba(0, 0, 0, 0.15), 0px 29px 29px 0px rgba(0, 0, 0, 0.13), 0px 66px 40px 0px rgba(0, 0, 0, 0.08), 0px 117px 47px 0px rgba(0, 0, 0, 0.02), 0px 183px 51px 0px rgba(0, 0, 0, 0.00);
}

.input_Attach{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 20;

    color: #373737;

    font-size: 21px;
    font-style: normal;
    line-height: 118.023%;
    gap: 30px;
}

.FileSercher{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 20;
}

.Lab.Acc{
    color: #D0D0D0;
    font-family: "Inter";
}

.input__file-icon-wrapper{
    font-family: "Inter";
    font-weight: 700;
    cursor: pointer;
    color: #FFF;
    font-size: 21px;
    font-style: normal;
    line-height: 118.023%;
    padding: 13px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;
    border-radius: 20px;
    background: #486ADA;
    width: 258px;
    height: 55px;
}

.input__file-icon-wrapper:hover{
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 16px 0px rgba(0, 0, 0, 0.15), 0px 29px 29px 0px rgba(0, 0, 0, 0.13), 0px 66px 40px 0px rgba(0, 0, 0, 0.08), 0px 117px 47px 0px rgba(0, 0, 0, 0.02), 0px 183px 51px 0px rgba(0, 0, 0, 0.00);
}

.input__file-button-text{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px;
    overflow: hidden;
    
    border-radius: 20px;
    border: 2px solid #486ADA;
    width: 456px;
    height: 55px;
}

@media(max-width: 1920px){
    .Ok{
            font-size: 1.1vw;
    }

    .MBody{
            font-size: 1.25vw;

    }

    .MHeader{
            font-size: 1.8vw;


    }
    .Form{
        margin-top: 7.8vw;
        gap: 2.6vw;
    }
    .FormHeader{
        font-size: 2.6vw;
    }

    .Modal{
        width: 38.1vw;
    }
    
    .FormWrapp{
        gap: 1.6vw;
    }
    
    .FIOWrapp{
        gap: 0.8vw;
    }
    
    .FIO{
        gap: 1.6vw;
    }
    
    
    .NameIn{
        border-radius: 1vw;
        width: 23.6vw;
        height: 2.9vw;
        padding: 0.8vw;
        font-size: 1.1vw;
    
    }
    
    .TelIn{
        border-radius: 1vw;
        width: 13.4vw;
        height: 2.9vw;
        padding: 0.8vw;
        font-size: 1.1vw;
    }

 
    
    .Lab{
        padding: 0.5vw 0.8vw;
        font-size: 1.1vw;
    }
    
    .SelectorVac{
        font-size: 1.1vw;
        padding: 0.5vw 0.8vw;
        width: 38.8vw;
        height: 2.9vw;
        border-radius: 1vw;
    }
    
    .DownWrapp{
        right: 0.4vw;
        border-radius: 0.5vw;
        width: 1.8vw;
        height: 1.8vw;
    }
    
    .Down{
        width: 0.8vw;
        height: 0.4vw;
    }
    
    .SelectorVac:focus .Down{
        transform: rotate(-180deg);
      }
    
    
    .css-1fdsijx-ValueContainer{
        width: 36.5vw;
       
    }
    
    .LabFix{
        width: 38.8vw;
        height: 14.8vw;
        padding: 0.8vw;
        border-radius: 1vw;
        font-size: 1.1vw;
    }
    
    .Submit{
        width: auto;
        border-radius: 1vw;
        gap: 0.5vw;
        padding: 0.7vw 0.8vw;
        font-size: 1.1vw;
        height: 2.9vw;
    }
    
    .Submit:hover{
        gap: 1vw;
        padding-right: 0.3vw;
    }
    .Attachment{
        height: 2.8vw;
    }
    
    .input_Attach{
        font-size: 1.1vw;
        gap: 1.6vw;
    }
    
    .input__file-icon-wrapper{
        font-size: 0.95vw;
        padding: 0.7vw 0.6vw;
        border-radius: 1vw;
        width: 13.2vw;
        height: 2.8vw;
    }
    
    .input__file-button-text{
        padding: 0.8vw;
        border-radius: 1vw;
        width: 23.6vw;
        height: 2.8vw;
    }
}

@media(max-width: 1000px){
    .Form{
        margin-top: 10vw;
        gap: 5vw;
        padding: 2vw;
    }
    .FormHeader{
        font-size: 5vw;
    }
    
    .FormWrapp{
        flex-direction: column;
        gap:3vw;
    }
    
    .FIOWrapp{
        gap: 1.5vw;
    }
    
    .FIO{
        gap: 3vw;
    }
    

    .Modal{
        width: 73.2vw;
    }

    .MBody{
        font-size: 2.4vw;
    }

    .MHeader{
        font-size: 3.5vw;
    }

    .Ok{
        font-size: 2.1vw;
    }
    
    .NameIn{
        border-radius: 2vw;
        width: 67.2vw;
        height: 5.5vw;
        padding: 1.5vw;
        font-size: 2.1vw;
    
    }
    
    .TelIn{
        border-radius: 2vw;
        width: 25.8vw;
        height: 5.5vw;
        padding: 1.5vw;
        font-size: 2.1vw;
    
    }
    
    .Lab{
        padding: 1vw 1.5vw;
        font-size: 2.1vw;
    }
    
    .SelectorVac{
        font-size: 2.1vw;
        padding: 1vw 1.5vw;
        width: 96vw;
        height: 5.5vw;
        border-radius: 2vw;
    }
    
    .DownWrapp{
        right: 10px;
        border-radius: 1vw;
        width: 3.5vw;
        height: 3.5vw;
    }
    
    .Down{
        width: 1.6vw;
        height: 0.8vw;
    }
    
    
    .css-1fdsijx-ValueContainer{
        width: 96vw;
       
    }
    
    .LabFix{
        width: 96vw;
        height: 23.3vw;
        padding: 1.5vw;
        border-radius: 2vw;
        font-size: 2.1vw;
    }
    
    .Submit{
        border-radius: 2vw;
        gap: 1vw;
        padding: 1.3vw 1.5vw;
        font-size: 2.1vw;
        height: 5.5vw;
    }
    
    .Submit:hover{
        gap: 2vw;
        padding-right: 0.5vw;
    }
    
    .input_Attach{
        font-size: 2.1vw;
        gap: 3vw;
        width: 67.2vw;
    }
    
    .input__file-icon-wrapper{
        font-size: 2vw;
        padding: 1.3vw 1.5vw;
        border-radius: 2vw;
        width: 25.7vw;
        height: 5.5vw;
    }
    
    .input__file-button-text{
        padding: 1.5vw;
        border-radius: 2vw;
        width: 67.2vw;
        height: 5.5vw;
    }
}

@media(max-width: 768px){
    .Ok{
            font-size: 2.7vw;

    }

    .Modal{
        top: 50vw;
        width: 90vw;
        left: 40px;
        
    }

    .Attach:hover .input__file-icon-wrapper{
        box-shadow: none;
    }

    .MBody{
            font-size: 3.1vw;
    }

    .MHeader{
            font-size: 4.6vw;
    }

    .Form{
        margin-top: 10.4vw;
        gap: 6.5vw;
        padding: 2.6vw;
    }
    .FormHeader{
        font-size: 6.5vw;
    }
    
    .FormWrapp{
        flex-direction: column;
        gap: 3.9vw;
    }
    
    .FIOWrapp{
        gap: 2vw;
    }
    
    .FIO{
        gap: 3.9vw;
    }
    
    
    .NameIn{
        border-radius: 2.6vw;
        width: 57.3vw;
        height: 7.2vw;
        padding: 2vw;
        font-size: 2.7vw;
    
    }
    
    .TelIn{
        border-radius: 2.6vw;
        width: 33.6vw;
        height: 7.2vw;
        padding: 2vw;
        font-size: 2.7vw;
    
    }
    
    .Lab{
        padding: 1.3vw 2vw;
        font-size: 2.7vw;
    }
    
    .SelectorVac{
        font-size: 2.7vw;
        padding: 1vw 2vw;
        width: 95vw;
        height: 7.2vw;
        border-radius: 2.6vw;
    }
    
    .DownWrapp{
        right: 10px;
        border-radius: 1.3vw;
        width: 4.6vw;
        height: 4.6vw;
    }
    
    .Down{
        width: 2.1vw;
        height: 1vw;
    }
    
    
    .css-1fdsijx-ValueContainer{
        width: 95vw;
       
    }
    
    .LabFix{
        width: 95vw;
        height: 30.3vw;
        padding: 2vw;
        border-radius: 2.6vw;
        font-size: 2.7vw;
    }
    
    .Submit{
        border-radius: 2.6vw;
        gap: 1.3vw;
        padding: 1vw 2vw;
        font-size: 2.7vw;
        height: 7.2vw;
        width: 26.6vw;
    }
    
    .Submit:hover{
        gap: 1.3vw;
        padding-right: 0.7vw;
    }

    .input__file-icon-wrapper:hover{
        box-shadow: none;
    }
    
    .input_Attach{
        font-size: 2.7vw;
        gap: 3.9vw;
        width: 57.3vw;
    }
    
    .input__file-icon-wrapper{
        font-size: 2.5vw;
        padding: 1vw 2vw;
        border-radius: 2.6vw;
        width: 33.6vw;
        height: 7.2vw;
    }
    
    .input__file-button-text{
        padding: 2vw;
        border-radius: 2.6vw;
        width: 57.3vw;
        height: 7.2vw;
    }
}

@media(max-width: 480px){

    .Form{
        margin-top: 16.7vw;
        gap: 10.4vw;
        padding: 4.2vw;
    }

    .Modal{
        width: 91.3vw;
        
    }

    .MBody{
        font-size: 5vw;
    }

    .MHeader{
        font-size: 7.3vw;
    }

    .input__file-icon-wrapper:hover{
        box-shadow: none;
    }
    

    .Ok{
        font-size: 4.4vw;
    }
    .FormHeader{
        width: 95vw;
        text-align: center;
        font-size: 9vw;
    }
    
    .FormWrapp{
        gap: 10.4vw;
    }
    
    .FIOWrapp{
        flex-direction: column;
        gap: 3.1vw;
    }
    
    .FIO{
        flex-direction: column;
        gap: 2.1vw;
    }
    
    
    .NameIn{
        border-radius: 4.2vw;
        width: 95vw;
        height:11.5vw;
        padding: 3.1vw;
        font-size: 4.4vw;
    
    }
    
    .TelIn{
        border-radius: 4.2vw;
        width: 95vw;
        height: 11.5vw;
        padding: 3.1vw;
        font-size: 4.4vw;
    
    }
    
    .Lab{
        padding:2.1vw 3.1vw;
        font-size: 4.3vw;
    }
    
    .SelectorVac{
        flex-direction: column;
        font-size: 4.4vw;
        padding:2.1vw 3.1vw;
        width: 95vw;
        height: 11.5vw;
        border-radius: 4.2vw;
    }
    
    .DownWrapp{
        right: 10px;
        border-radius: 4.2vw;
        width: 7.3vw;
        height: 7.3vw;
    }
    
    .Down{
        width: 3.3vw;
        height: 1.7vw;
    }
    
    
    .css-1fdsijx-ValueContainer{
        width: 95vw;
       
    }
    
    .LabFix{
        width: 95vw;
        height: 95.2vw;
        padding: 3.1vw;
        border-radius: 4.2vw;
        font-size: 4.4vw;
    }
    
    .Submit{
        width: 45vw;
        border-radius: 4.2vw;
        gap: 10px;
        padding:2.1vw 3.1vw;
        font-size: 4.4vw;
        height: 11.5vw;
    }
    
    .Submit:hover{
        gap: 10px;
        padding:2.1vw 3.1vw;
        box-shadow: none;
    }
    .Attachment{
        height: 7vw;
        left: 0;
    }

    
    .input_Attach{
        flex-direction: column;
        font-size: 4.4vw;
        gap: 6.3vw;
        width: 95vw;
    }

    .FileSercher.GG{
        gap: 10px;
        flex-direction: row-reverse;
        width: 95vw;
    }

    .Lab.Acc{
        z-index: 10;
        padding: 0;
        padding-top:  4vw;
    }
    
    .input__file-icon-wrapper{
        font-size: 4vw;
        border-radius: 4.2vw;
        width: 55vw;
        padding: 1vw 4vw;
        height: 11.5vw;
    }
    
    .input__file-button-text{
        padding: 3.1vw;
        border-radius: 4.2vw;
        width: 95vw;
        height: 11.5vw;
    }
    .Ok:hover{
        box-shadow: none;
    }

    
    .Attach:active .input__file-icon-wrapper{
        background: #486ADA;
    }
}

@media(max-width: 320px){
    .input__file-button-text{
        padding: 4vw;
    }

    .Lab{
        font-size: 6.5vw;
    }
    .Attach:active .input__file-icon-wrapper{
        background: #373737;
    }
    
    .input__file-icon-wrapper:hover{
        box-shadow: none;
    }

    .Modal{
        width: 87.5vw;
        padding: 6.3vw;
    }

    .MBody{
        font-size: 5vw;
    }

    .MHeader{
        font-size: 8.8vw;
    }

    .FormWrapp{
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .Lab.Acc{
        padding: 0;
        padding-top:  0vw;
    }

    .Ok{
        font-size: 6.6vw;
    }
    .Form{
        margin-top: 25vw;
        gap: 0vw;
        padding: 6.3vw;
    }
    .Submit:hover{
        gap: 10px;
        padding: 6.3vw;
        box-shadow: none;
    }

    .LabFix{
        height: 145.8vw;
    }

    .input__file-button-text{
        height: 15vw;
    }

    .NameIn{
        height: 15vw;
    }

    .TelIn{
        height: 15vw;
    }

    .FileSercher.GG{
        flex-direction: column;
        gap: 3.1vw;
    }

    .Submit{
        justify-content: center;
        border-radius: 4.2vw;
        gap: 10px;
        padding:15px 15px;
        font-size: 21px;
        width: 95vw;
        height: 15vw;
    }

    .input_Attach{
        gap: 6.3vw;
    }

    .input__file-icon-wrapper{
        width: 95vw;
        font-size: 21px;
        justify-content: center;
        gap: 10px;
        height: 15vw;
    }
    .FormHeader{
        width: 89.5vw;
        text-align: center;
        font-size: 9.4vw;
    }
}