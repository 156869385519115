.TechWrapper{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1518px;
    height: 878px;
}

.TechWrapperSliderWrapper{
    overflow-y: auto;
	-webkit-overflow-scrolling:touch;
    margin-top: 10.4vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100vw;
    height: 122.8vw;
    overflow: visible;
}

.HeaderTechs{
    width: 1518px;
    height: 496px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.BodyTechs{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px 20px;
    width: 1518px;
    height: 424px;
}

.BodyTech{
    overflow: hidden;
  
   
    padding: 30px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 486px;
    height: 424px;
    border-radius: 60px;
    background: #486ADA;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


.HeaderTech{
    overflow: hidden;
    display: flex;
    background-size: cover;
    
    flex-direction: row;
    padding: 30px;
    width: 744px;
    height: 424px;
    border-radius: 60px;
    background: #486ADA;
    background-repeat: no-repeat;
    background-position: 14%;
}

.BodyTBodyWrapp{
    margin-right: 30px;
    height: 364px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.BodyTHeader{
    width: 91px;
    border-bottom: solid 3px #fff;
    color: #FFF;
    font-weight: 700;
    font-size: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 10px;
}

.HeadTHeader{
    width: 128px;
    border-bottom: solid 3px #fff;
    color: #FFF;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 10px
}

.BodyTBody li{
    color: #FFF;
    font-size: 16px;
    max-width: 205px;

}

.BodyTBody ol{
    color: #FFF;
    font-size: 16px;
    max-width: 200px;
    margin-left: -22px;
    margin-top: 10px;

}

li{
    list-style-type: disc;
}

.BodyTBody{
    padding-left: 30px;
}

.DescriptBody ul{
    list-style: inside;
}


@media (max-width: 1920px){
    .TechWrapper{
        margin-top: 2.6vw;
        width: 79.1vw;
        height: 45.7vw;
    }

    .HeaderTechs{
        justify-content: space-between;
        width: 79.1vw;
        height: 22.1vw;
    
    }

    .BodyTechs{
        justify-content: space-between;
        margin-top: 1.6vw;
        gap: 0;
        width: 79.1vw;
        height: 22.1vw;
    }
    
    .BodyTech{
        padding: 1.6vw;
        height: 22.1vw;
        width: 25.3vw;
        border-radius: 3.1vw;
    }

    .BodyTBody{
        padding-left: 1.6vw;
    }
    

    .HeaderTech{   
        padding: 1.6vw;
        width: 38.8vw;
        height: 22.1vw;
        border-radius: 3.1vw;
        background-size: 100%;
    }

    .BodyTBodyWrapp{
        margin-right: 1.6vw;
        height: 19vw;
    }

    .BodyTHeader{
        width: 4.7vw;
        border-bottom: solid 0.2vw #fff;
        color: #FFF;
        font-size: 1.4vw;
        padding-bottom: 1vw;
    }

    .HeadTHeader{
        width: 6.7vw;
        border-bottom: solid 3px #fff;
        font-size: 1.6vw;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .BodyTBody li{
        font-size: 0.8vw;
        padding-bottom: 0;
        max-width: 12.2vw;
    }
    .BodyTBody ol{
        font-size: 0.8vw;
        max-width: 12.2vw;
        margin-left: -22px;
        margin-top: 10px;
    
    }
}

@media (max-width: 1000px){
    .TechWrapper{
        margin-top: 5vw;
        width: 96vw;
        height: 133.2vw;
    }
    

    .BodyTBody{
        padding-left: 3vw;
    }
    
    .HeaderTechs{
        width: 96vw;
        height: 42.4vw;
        justify-content: center;
    }
    
    .BodyTechs{
        margin-top: 3vw;
        gap: 3vw 2vw;
        width: 96vw;
        height: 87.8vw;
    }
    
    .BodyTech{
        background-size: cover;
        padding: 3vw;
        width: 47vw;
        height: 42.4vw;
        border-radius: 6vw;
        background-position: 50%;
    }
    
    .HeaderTech{
        background-size: cover;
        padding: 3vw;
        width: 96vw;
        height: 42.4vw;
        border-radius: 6vw;
        background-position: 14%;
    }
    
    .BodyTBodyWrapp{
        margin-right: 4.4vw;
        height: 36.4vw;
    }
    
    .BodyTHeader{
        width: 9.1vw;
        border-bottom: solid 3px #fff;
        font-size: 2.8vw;
        padding-bottom: 1vw;
    }
    
    .HeadTHeader{
        width: 12.6vw;
        border-bottom: solid 3px #fff;
        font-size: 2.8vw;
        padding-bottom: 1vw;
    }
    
    .BodyTBody li{
        font-size: 1.6vw;
        max-width: 23vw;
    }
    .BodyTBody ol{
        font-size: 1.6vw;
        max-width: 23vw;
        margin-left: -22px;
        margin-top: 10px;
    
    }
}

@media (max-width: 768px){

    .TechWrapper{
        margin-top: 6.5vw;
        width: 95vw;
        height: 286.5vw;
    }
    
    .HeaderTechs{
        width: 95vw;
        height: 55.2vw;
        justify-content: center;
        margin-bottom: 2.6vw;
    }
    
    .BodyTechs{
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0;
        gap: 0;
        width: 95vw;
        height: 229vw;
    }
    
    .BodyTBody{
        padding-left: 3.9vw;
    }
    .BodyTech{
        background-size: cover;
        padding: 3vw;
        width: 95vw;
        height: 55.2vw;
        border-radius: 6vw;
        background-position: 50%;
    }
    
    .HeaderTech{
        background-size: cover;
        padding: 3.9vw;
        width: 95vw;
        height: 55.2vw;
        border-radius: 7.8vw;
        background-position: 0;
    }
    
    .BodyTBodyWrapp{
        margin-right: 4.4vw;
        height: 47.4vw;
    }
    
    .BodyTHeader{
        width:11.8vw;
        border-bottom: solid 0.4vw #fff;
        font-size: 3.9vw;
        padding-bottom: 1vw;
    }
    
    .HeadTHeader{
        width: 12.6vw;
        border-bottom: solid 3px #fff;
        font-size: 3.9vw;
        padding-bottom: 1vw;
    }
     
    .BodyTBody li{
        font-size: 2.1vw;
        max-width: 30vw;
    }

    .BodyTBody ol{
        font-size: 2.1vw;
        max-width: 30vw;
        margin-left: -22px;
        margin-top: 10px;
    
    }
}
@media (max-width: 320px){
    .TechWrapperSliderWrapper{
        margin-top: 15.6vw;
        width: 100vw;
        height: 154.2vw;
    }
}

