.FivethBlock{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    font-family: 'Inter';

    @media(max-width: 1000px){
        justify-content: center;
    }
    
}

.FivethWrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    width: 1518px;
}

.WorkWrapper{
    height: 298px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 30px;
    border: 2px solid #486ADA;
    padding: 30px;
    width: 941px;
}

.WorkHeader{
    font-family: 'Inter-Bold';
    color: #373737;
    font-size: 50px;
    line-height: 116.8%;
}
.WorkBody{
    padding-left: 30px;
    width: 881px;
    margin-top: 30px;
}

.WorkBody ul{
    list-style: outside;
}
.WorkBody li{

    color: #373737;
    font-size: 21px;
    line-height: 118.023%;
}

.Numbers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 30px;
    border-radius: 30px;
    background: #486ADA;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 7px 16px 0px rgba(0, 0, 0, 0.15), 0px 29px 29px 0px rgba(0, 0, 0, 0.13), 0px 66px 40px 0px rgba(0, 0, 0, 0.08), 0px 117px 47px 0px rgba(0, 0, 0, 0.02), 0px 183px 51px 0px rgba(0, 0, 0, 0.00);
    width: 547px;
    height: 298px;
    background-image: url('./images/AngelsBlue.webp');
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: 100%;
}

.NumHeader{
    color: #FFF;
    font-size: 21px;
    line-height: 118.023%;
}

.NumWrapper{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 487px;
    height: 238px;
}

.NumbersInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 406px;
    height: 95px;
}

.InfoWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 406px;
}

.Phone{
    width: 40px;
    height: 40px;
    background-image: url('./images/Phone.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 15px;
}

.PhoneInfo{
    color: #FFF;
    font-size: 21px;
    line-height: 116.8%;
    margin-right: 15px;
}

.NumNote{
    cursor: pointer;
    border: none; outline: none;background: none;
    width: 25px;
    height: 25px;
    background-image: url('./images/Lists.svg');
    background-size: cover;
}

.NumberFooter{
    color: #FFF;
    font-size: 21px;
    line-height: 118.023%; 
}

.Copied{
    border-radius: 200px;
}

.NumNote:hover{
    background-image: url('./images/PhoneGay.svg');
}

a{
    color: #FFF;
    text-decoration: none;
  }



@media (max-width: 1920px){

    .FivethBlock{
        padding: 0;
        margin-top: 1.6vw;
    }

    .FivethWrapper{
        flex-direction: row;
        width: 79.1vw;
    }

    .WorkWrapper{
        border-radius: 1.6vw;
        border: 0.1vw solid #486ADA;
        padding: 1.6vw;
        width: 49vw;
        height: 15.5vw;
        margin-right: 1.6vw;
    }

    .WorkHeader{
        font-size: 2.6vw;
    }
    .WorkBody{
        padding-left: 1.2vw;
        width: auto;
        margin-top: 1.6vw;
    }
    .WorkBody li{
        font-size: 1.1vw;
    }

    .Numbers{
        margin-top: 0;
        padding: 0 1.6vw;
        border-radius: 1.6vw;
        width: 28.5vw;
        height: 15.5vw;
        background-size: 73%;
    }

    .NumHeader{
        font-size: 1.1vw;
    }


    .NumWrapper{
        margin-top: 1.6vw;
        width: 25.4vw;
        height: 12.4vw;
    }
    
    .NumbersInfo{
        width: 21.1vw;
        height: 4.9vw;
    }

    .InfoWrapper{
        width: 21.1vw;
    }
    
    .Phone{
        width: 2.1vw;
        height: 2.1vw;
        margin-right: 1vw;
    }

    .PhoneInfo{
        font-size: 1vw;
        margin-right: 1vw;
    }

    .NumNote{
        width: 1.3vw;
        height: 1.3vw;
    }

    .NumberFooter{
        font-size: 1.1vw;
    }

    .Copied{
        border-radius: 20vw;
    }

}

@media (max-width: 1000px){
    .FivethBlock{
        padding: 2vw;
        margin-top: 2vw;
    }
    
    .WorkWrapper{
        height: auto;
        border-radius: 3vw;
        border: 0.2vw solid #486ADA;
        padding: 3vw;
        width: 96vw;
        margin-right: 0;
    }
    .FivethWrapper{
        flex-direction: column;
        width: 79.1vw;
        align-items: center;
    }
    
    .WorkHeader{
        font-size: 5vw;
    }
    .WorkBody{
        padding-left: 3vw;
        width: auto;
        margin-top: 3vw;
    }
    .WorkBody li{

        font-size: 2.1vw;
    }
    
    .Numbers{
        padding: 0 3vw;
        margin-top: 3vw;
        border-radius: 3vw;
        width: 96vw;
        height: 29.8vw;
        background-size: 45%;
    }
    
    .NumHeader{
        font-size: 2.1vw;
    }
    
    .NumWrapper{
        margin-top: 3vw;
        width: 48.7vw;
        height: 23.8vw;
    }
    
    .NumbersInfo{
        width: 40.6vw;
        height: 9.5vw;
    }
    
    .InfoWrapper{
        width: 40.6vw;
    }
    
    .Phone{
        width: 4vw;
        height: 4vw;
        margin-right: 1.5vw;
    }
    
    .PhoneInfo{
        font-size: 2.1vw;
        margin-right: 1.5vw;
    }
    
    .NumNote{
        width: 2.5vw;
        height: 2.5vw;
    }
    
    .NumberFooter{
        font-size: 2.1vw; 
    }
    
    .Copied{
        border-radius: 50vw;
    }
}

@media (max-width: 768px){
    .FivethBlock{
        width: auto;
        margin-top: 5vw;
    }
    
    .WorkWrapper{
        height: auto;
        border-radius: 3.9vw;
        border: 0.2vw solid #486ADA;
        padding: 3.9vw;
        width: 95vw;
    }
    .FivethWrapper{
        flex-direction: column;
        width: 95vw;
        align-items: center;
    }
    
    .WorkHeader{
        font-size: 6.5vw;
    }
    .WorkBody{
        padding-left: 3.9vw;
        width: auto;
        margin-top: 3.9vw;
    }
    .WorkBody li{
        font-size: 2.7vw;
    }
    
    .Numbers{
        padding: 0 3.9vw;
        margin-top: 3.9vw;
        border-radius: 3.9vw;
        width: 95vw;
        height: 38.8vw;
        background-size:52%;
    }
    
    .NumHeader{
        font-size: 2.7vw;
    }
    
    .NumWrapper{
        margin-top: 3.9vw;
        width: 63.4vw;
        height: 31vw;
    }
    
    .NumbersInfo{
        width: 52.9vw;
        height: 12.4vw;
    }
    
    .InfoWrapper{
        width: 52.9vw;
    }
    
    .Phone{
        width: 5.2vw;
        height: 5.2vw;
        margin-right: 2vw;
    }
    
    .PhoneInfo{
        font-size: 2.7vw;
        margin-right: 2vw;
    }
    
    .NumNote{
        width: 3.3vw;
        height: 3.3vw;
    }
    
    .NumberFooter{
        font-size: 2.7vw; 
    }
    
    .Copied{
        border-radius: 20vw;
    }
}


@media (max-width: 480px){
    .FivethBlock{
        width: auto;
        margin-top:30px;
    }
    
    .WorkWrapper{
        height: auto;
        border-radius: 6.3vw;
        border: 0.4vw solid #486ADA;
        padding: 4.2vw;
        width: 92vw;
        align-items: center;
        margin-right: 0;
    }
    .FivethWrapper{
        flex-direction: column;
        width: 92vw;
        align-items: center;
    }
    
    .WorkHeader{
        font-size: 9vw;
    }
    
    .WorkBody li{
        font-size:4.4vw;
        color: #373737;
    }
    .WorkBody{
        padding-left: 6.3vw;
        width: auto;
        margin-top: 6.3vw;

    }
    
    .Numbers{

        padding: 0 4.2vw;
        margin-top: 4.2vw;
        border-radius: 4.2vw;
        width: 92vw;
        height: 57.3vw;
        background-image: none;
    }
    
    .NumHeader{
        font-size: 4vw;
    }
    
    .NumWrapper{
        margin-top: 4.2vw;
        width: 83.3vw;
        height: 49vw;
    }
    
    .NumbersInfo{
        width: 83.3vw;
        height: 19.8vw;
    }
    
    .InfoWrapper{
        width: 83.3vw;
    }
    
    .Phone{
        width: 8vw;
        height: 8vw;
        margin-right: 3.1vw;
    }
    
    .PhoneInfo{
        font-size: 4vw;
        margin-right: 3.1vw;
    }
    
    .NumNote{
        width: 5.4vw;
        height: 5.4vw;
    }
    
    .NumberFooter{
        font-size: 4vw; 
    }
    
    .Copied{
        border-radius: 60vw;
    }
}

@media (max-width: 320px){
    .FivethBlock{
        width: auto;
        margin-top: 30px;
    }
    
    .WorkWrapper{
        height: auto;
        border-radius: 9.4vw;
        border: 0.4vw solid #486ADA;
        padding: 4.7vw;
        width: 87.5vw;
        margin-right: 0;
    }
    .FivethWrapper{
        flex-direction: column;
        width: 87.5vw;
        align-items: center;
    }
    
    .WorkHeader{
 
        font-size: 9vw;
    }
    
    .WorkBody li{
        font-size:6.6vw;
        color: #373737;
    }
    .WorkBody{
        padding-left: 9.4vw;
        width: auto;
        margin-top: 9.4vw;
    }
    
    .Numbers{
        padding: 0;
        margin-top: 6.3vw;
        border-radius: 9.4vw;
        width: 87.5vw;
        height: 112.5vw;
        background-image: none;
    }
    
    .NumHeader{
        font-size: 6.6vw;
    }
    
    .NumWrapper{
        padding: 4.7vw 4.7vw;
        margin-top: 0;
        width: 87.5vw;
        height: 112.5vw;
    }
    
    .NumbersInfo{
        align-items: flex-start;
        width: 87.5vw;
        height: 35.9vw;
    }
    
    .InfoWrapper{
        width: 78.1vw;
        height: 12.5vw;
    }
    
    .Phone{
        width: 12.5vw;
        height: 12.5vw;
        margin-right: 4.7vw;
    }
    
    .PhoneInfo{
        width: 60.9vw;
        font-size: 6.6vw;
    }
    
    .NumNote{
        display: none;
    }
    
    .NumberFooter{
        font-size: 6.6vw; 
    }
    
    .Copied{
        border-radius: 20vw;
    }
}
